/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import InputMask from 'react-input-mask';

import { Input, InputProps, InputWrapperProps } from '@mantine/core';

type Props = InputProps & { maskChar?: string | null } & Omit<
    InputWrapperProps,
    'children'
  > & {
    mask: string;
    testId?: string;
  };

export function MaskedInput({ testId = '', ...props }: Props) {
  return (
    <Input.Wrapper {...props}>
      <Input data-testid={testId} component={InputMask} {...props} />
    </Input.Wrapper>
  );
}
