import { getEmptyOption } from 'shared/helpers';
import { SkuStatus } from 'shared/types';

export const ACCESS_TOKEN = 'accessToken';
export const REFRESH_TOKEN = 'refreshToken';
export const USER_DATA = 'userData';

export const HALTER_RANCH_LOCATION_ID = '60abbcac-4127-47a5-8f83-d5a9c5617452';
export const ROOT_CATEGORY_ID = '00000000-0000-0000-0000-000000000000';

export const defaultSelectOptions = {
  value: '',
  label: 'Not Selected',
};

export const DEFAULT_PAGE_SIZE = 10;

export const SWITCH_GRID_TOP_OFFSET = 28;

export const STATE_OPTIONS = [
  getEmptyOption('All States'),
  { label: 'Enabled', value: SkuStatus.Enabled },
  { label: 'Disabled', value: SkuStatus.Disabled },
  { label: 'Draft', value: SkuStatus.Draft },
];

export enum ResponseStatus {
  UNAUTHORIZED = 401,
}

export enum ShipmentCycleTabValue {
  profile = 'profile',
  wallet = 'wallet',
  address = 'address',
  clubMember = 'club-member',
  orders = 'orders',
}

export enum Environment {
  Qa = 'qa',
  Local = 'local',
  Staging = 'staging',
  Development = 'development',
  Production = 'production',
}
