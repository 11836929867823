import { ReactNode } from 'react';
import { Button, Text, ButtonProps } from '@mantine/core';
import { openConfirmModal } from '@mantine/modals';
import { CANCEL_BTN, CONFIRM_BTN } from 'shared';

interface Props extends ButtonProps {
  title?: string;
  confirmLabel?: string;
  cancelLabel?: string;
  modalSize?: string | number;
  onCancel?: () => void;
  onConfirm: () => void;
  children: ReactNode;
  label: string;
}

export const ConfirmModal = ({
  title,
  children,
  onConfirm,
  onCancel,
  label,
  modalSize = 'md',
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  ...buttonProps
}: Props) => {
  const openModal = () =>
    openConfirmModal({
      size: modalSize,
      title: (
        <Text
          data-testid="confirmation-action-box-header"
          size={'md'}
          weight={600}
        >
          {title ?? 'Confirm action'}
        </Text>
      ),
      children,
      labels: {
        confirm: <Text data-testid={CONFIRM_BTN}>{confirmLabel}</Text>,
        cancel: <Text data-testid={CANCEL_BTN}>{cancelLabel}</Text>,
      },
      onCancel,
      onConfirm,
    });

  return (
    <Button onClick={openModal} {...buttonProps}>
      {label}
    </Button>
  );
};
