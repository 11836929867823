import { Link, useNavigate } from 'react-router-dom';
import { BackButton, ContentLayout } from 'components';
import { useEditDiscount } from 'pages/discounts/hooks';
import { Form } from '../form';

export const Edit = () => {
  const navigate = useNavigate();
  const {
    tiersOptions,
    locationOptions,
    initialValues,
    isLoading,
    processing,
    handleSubmit,
  } = useEditDiscount();

  const { name } = initialValues ?? {};

  return (
    <ContentLayout
      title={name}
      isLoading={isLoading}
      processing={processing}
      leftAction={<BackButton component={Link} onClick={() => navigate(-1)} />}
    >
      <Form
        initialValues={initialValues}
        options={locationOptions}
        tiersOptions={tiersOptions}
        handleSubmit={handleSubmit}
      />
    </ContentLayout>
  );
};
