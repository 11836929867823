import { Link, useNavigate, useParams } from 'react-router-dom';
import { BackButton, ContentLayout } from 'components';
import { useSuborder } from '../../hooks';
import { SuborderForm } from '../form';

export const EditSuborder = () => {
  const navigate = useNavigate();
  const { id: parentOrderId = '' } = useParams();
  const {
    number,
    summary,
    isLoading,
    isEditing,
    giftPackage,
    initialValues,
    defaultGiftPackage,
    giftPackageOptions,
    defaultClubMembership,
    isComplianceCheckProcessing,
    handleEditSuborder,
    handleRecheckCompliance,
  } = useSuborder({ parentOrderId });

  const title = `Order ${number}`;
  const goBack = () => navigate(-1);

  return (
    <ContentLayout
      title={title}
      isLoading={isLoading}
      processing={isEditing}
      leftAction={<BackButton component={Link} onClick={goBack} />}
    >
      <SuborderForm
        summary={summary}
        giftPackage={giftPackage}
        initialValues={initialValues}
        giftPackageOptions={giftPackageOptions}
        defaultGiftPackage={defaultGiftPackage}
        defaultClubMembership={defaultClubMembership}
        isComplianceCheckProcessing={isComplianceCheckProcessing}
        handleSubmit={handleEditSuborder}
        handleRecheckCompliance={handleRecheckCompliance}
      />
    </ContentLayout>
  );
};
