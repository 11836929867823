import { AddressDto, BACK_BTN } from 'shared';
import { AddressForm } from '../../forms/AddressForm';
import { useForm, yupResolver } from '@mantine/form';
import { addressEditSchema } from '../../forms/schemes';
import { Button, Container } from '@mantine/core';

type Props = {
  initialValues: AddressDto;
  handleSubmit: (values: AddressDto, update_orders?: boolean) => void;
  processing: boolean;
  opened: boolean;
  isUnpaid: boolean;
  setIsNotVerify: React.Dispatch<React.SetStateAction<boolean>>;
  close: () => void;
  goBack: () => void;
};

export const EditAddress = ({
  initialValues,
  handleSubmit,
  processing,
  opened,
  isUnpaid,
  setIsNotVerify,
  close,
  goBack,
}: Props) => {
  const form = useForm({
    initialValues: initialValues,
    validate: yupResolver(addressEditSchema),
    validateInputOnBlur: true,
  });
  return (
    <Container size="sm">
      <AddressForm
        form={form}
        isEdit
        isUnpaid={isUnpaid}
        handleSubmit={handleSubmit}
        processing={processing}
        opened={opened}
        setIsNotVerify={setIsNotVerify}
        close={close}
      />
      <Container size="xs" my="xl">
        <Button
          data-testid={BACK_BTN}
          fullWidth
          variant="white"
          onClick={goBack}
        >
          Back to Addresses
        </Button>
      </Container>
    </Container>
  );
};
