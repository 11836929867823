import { useCallback, useState } from 'react';
import { useToast } from './useToast';

const getFormData = ({
  file,
  fields,
}: {
  file: File;
  fields?: { [key: string]: string };
}) => {
  if (!fields) return null;

  const formData = new FormData();
  for (const name in fields) {
    formData.append(name, fields[name]);
  }

  formData.append('file', file);

  return formData;
};

export const useUploadFileByPreSignedUrl = () => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const uploadFileToS3 = useCallback(
    async ({
      file,
      fields,
      preSignedUrl,
    }: {
      file: File | null;
      fields?: { [key: string]: string };
      preSignedUrl: string;
    }) => {
      if (!file) return null;

      const formData = getFormData({ file, fields });

      try {
        setIsLoading(true);
        const response = await fetch(preSignedUrl, {
          method: formData ? 'POST' : 'PUT',
          body: formData ?? file,
          ...(formData ? {} : { headers: { 'Content-Type': '' } }),
        });

        const { origin, pathname } = new URL(response.url);

        return `${origin}${pathname}`;
      } catch (err) {
        if (err instanceof Error)
          toast.error(`Error uploading file: ${err.message}`);
      } finally {
        setIsLoading(false);
      }
    },
    [toast],
  );
  return { uploadFileToS3, isLoading };
};
