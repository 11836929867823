import * as Yup from 'yup';
import {
  dateOfBirthValidator,
  requiredDateValidationRule,
  requiredNumberValidationRule,
  requiredStringValidationRule,
} from 'shared';

const giftPackageItemSchema = Yup.object({
  sku_name: requiredStringValidationRule('Product'),
  quantity: requiredNumberValidationRule('Qnt'),
});

const giftPackageItemsSchema = Yup.object({
  name: Yup.string(),
  gift_package_items: Yup.array().of(giftPackageItemSchema),
});

const giftPackagesSchema = Yup.array().of(giftPackageItemsSchema);

export const corporateOrderSchema = Yup.object({
  customer_id: requiredStringValidationRule('Customer'),
  customer_card_id: requiredStringValidationRule('Card'),
  freight_company: requiredStringValidationRule('Freight Company'),
  freight_method: requiredStringValidationRule('Freight Method'),
  gift_packages: giftPackagesSchema,
});

export const subOrderSchema = Yup.object({
  items: Yup.array().min(1, 'Gift Package is required'),
  customer_id: requiredStringValidationRule('Customer'),
  updated_by_id: requiredStringValidationRule('Updated By'),
  deplete_location_id: requiredStringValidationRule('Deplete Location'),
  deplete_inventory_location_id: requiredStringValidationRule(
    'Deplete Inventory Location',
  ),
  freight_company: requiredStringValidationRule('Freight Company'),
  freight_method: requiredStringValidationRule('Freight Method'),
  supply_type: requiredStringValidationRule('Supply Type'),
  shipping_date: requiredDateValidationRule('Shipping Date'),
  tracking_number: Yup.string().nullable(),
  shipping_address: Yup.object({
    first_name: requiredStringValidationRule('First Name'),
    last_name: requiredStringValidationRule('Last Name'),
    business: Yup.string().nullable(),
    phone_number: requiredStringValidationRule('Phone Number'),
    email: requiredStringValidationRule('Email'),
    date_of_birth: dateOfBirthValidator.required('Date of Birth is required'),
    address_line_1: requiredStringValidationRule('Address Line 1'),
    address_line_2: Yup.string().nullable(),
    city: requiredStringValidationRule('City'),
    state: requiredStringValidationRule('State'),
    zip_code: requiredStringValidationRule('Zip Code'),
    country: requiredStringValidationRule('Country'),
  }),
});
