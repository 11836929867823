import { Flex, Grid, Select, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { DefaultGiftPackage } from '../../../types';
import {
  DROPDOWN,
  GiftPackage,
  OrderItem,
  SelectOption,
  SubOrderInitialValues,
} from 'shared';

type Props = {
  form: UseFormReturnType<SubOrderInitialValues>;
  summaryItems?: JSX.Element[];
  giftPackageOptions: SelectOption[];
  giftPackage?: { [key: string]: GiftPackage };
  defaultGiftPackage?: DefaultGiftPackage;
};

export const InventoryDetails = ({
  form,
  summaryItems,
  giftPackage,
  giftPackageOptions,
  defaultGiftPackage,
}: Props) => {
  const { id, discountedPrice, retailPrice, discountPrice, total } =
    defaultGiftPackage ?? {};

  const { values, setFieldValue } = form;

  const { gift_package_id } = values ?? {};
  const isDefaultPackages = defaultGiftPackage && id === gift_package_id;

  const giftPackedItems = giftPackage?.[gift_package_id]?.gift_package_items;

  return (
    <>
      <Grid.Col mb="xs">
        <Text size="lg" weight={600}>
          Inventory Details
        </Text>
      </Grid.Col>
      <Grid.Col span={6}>
        <Grid.Col span={12} p={0}>
          <Select
            required
            label="Gift Package"
            data-testid={`gift-package-${DROPDOWN}`}
            data={giftPackageOptions ?? []}
            {...form.getInputProps('gift_package_id')}
            onChange={(value) => {
              if (!value) return;

              setFieldValue(
                'items',
                giftPackage?.[value].gift_package_items as OrderItem[],
              );
              setFieldValue('gift_package_id', value);
            }}
          />
        </Grid.Col>
        <Grid.Col span={12} p={0} mt={10}>
          {giftPackedItems?.map(({ id, sku, quantity }) => (
            <Flex key={id} justify="space-between" mt={15}>
              <Text size="sm">
                {sku?.product_name} [{sku?.sku_number}]
              </Text>
              <Text size="sm">x{quantity}</Text>
            </Flex>
          ))}
        </Grid.Col>
      </Grid.Col>

      {isDefaultPackages ? (
        <>
          <Grid.Col span={6}>
            <Grid p={0}>
              <Grid.Col span={3}>
                <Text weight={600} size="sm" align="right">
                  Retail Price
                </Text>
                <Text size="sm" align="right">
                  {retailPrice}
                </Text>
              </Grid.Col>
              <Grid.Col span={3}>
                <Text weight={600} size="sm" align="right">
                  Discount
                </Text>
                <Text size="sm" align="right">
                  {discountPrice}
                </Text>
              </Grid.Col>
              <Grid.Col span={3}>
                <Text weight={600} size="sm" align="right">
                  Discounted Price
                </Text>
                <Text size="sm" align="right">
                  {discountedPrice}
                </Text>
              </Grid.Col>
              <Grid.Col span={3}>
                <Text weight={600} size="sm" align="right">
                  Total
                </Text>
                <Text size="sm" align="right">
                  {total}
                </Text>
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col>{summaryItems}</Grid.Col>
        </>
      ) : (
        <Grid.Col span={6}>
          <Text weight={600} align="center" mt={30}>
            The calculations will appear after saving the changes!
          </Text>
        </Grid.Col>
      )}
    </>
  );
};
