import * as Yup from 'yup';

import {
  dateOfBirthValidator,
  emailValidationRule,
  phoneNumberValidator,
  requiredStringValidationRule,
} from 'shared';

export const generalInformationSchema = Yup.object({
  first_name: requiredStringValidationRule('First Name'),
  last_name: requiredStringValidationRule('Last Name'),
  email: emailValidationRule,
  phone_number: phoneNumberValidator,
  customer_source_id: Yup.string().nullable(),
  date_of_birth: dateOfBirthValidator,
  preferred_primary_location_id: Yup.string().nullable(),
  secondary_location_id: Yup.string().nullable(),
  email_opt_in: Yup.boolean(),
  sms_opt_in: Yup.boolean(),
  pos_notes: Yup.string().nullable(),
  system_notes: Yup.string().nullable(),
});
