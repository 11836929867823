import { Button, Grid, Text } from '@mantine/core';
import { SingleValue } from 'react-select';
import { SelectAsync } from 'components';
import { useDiscounts } from 'hooks';
import { PROMOCODE_DROPDOWN, SelectOption } from 'shared';

type Props = {
  disabledPromocode: boolean;
  isApplied: boolean;
  isProcessingApplied: boolean;
  promocodeAppliedMessage?: string;
  defaultPromocode?: SelectOption;
  handleApplyPromocode: () => void;
  setPromocodeId: React.Dispatch<React.SetStateAction<string | null>>;
};

export const Promocode = ({
  disabledPromocode,
  isApplied,
  isProcessingApplied,
  defaultPromocode,
  promocodeAppliedMessage,
  setPromocodeId,
  handleApplyPromocode,
}: Props) => {
  const {
    hasMore,
    discountOptions,
    isLoading,
    setPageSelectAsync,
    handleSetSearchString,
  } = useDiscounts({ isSelectAsync: true });

  return (
    <Grid mt={35}>
      <Grid.Col span={4}>
        <SelectAsync
          isSearchable
          searchByString={({ search_string }) =>
            handleSetSearchString(search_string)
          }
          data-testid={PROMOCODE_DROPDOWN}
          label="Promocode"
          setPage={setPageSelectAsync}
          hasMore={hasMore}
          isLoading={isLoading}
          defaultValue={defaultPromocode}
          options={discountOptions}
          onChange={(item) => {
            const { value } = (item as SingleValue<SelectOption>) ?? {};
            if (value) {
              setPromocodeId(value);
            } else {
              setPromocodeId(null);
            }
          }}
        />
      </Grid.Col>
      <Grid.Col span={2} mt={24}>
        <Button
          disabled={disabledPromocode}
          loading={isApplied || isProcessingApplied}
          onClick={handleApplyPromocode}
        >
          Apply
        </Button>
      </Grid.Col>
      <Grid.Col>
        {promocodeAppliedMessage && (
          <Text color="gray" size={12}>
            {promocodeAppliedMessage}
          </Text>
        )}
      </Grid.Col>
    </Grid>
  );
};
