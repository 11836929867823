import { Link, useNavigate, useParams } from 'react-router-dom';
import { BackButton, ContentLayout } from 'components';
import { SuborderForm } from '../form';
import { useSuborder } from '../../hooks';

export const CreateSuborder = () => {
  const navigate = useNavigate();
  const { id: parentOrderId = '' } = useParams();
  const {
    giftPackage,
    initialValues,
    giftPackageOptions,
    asyncSelectUsers,
    defaultClubMembership,
    isCreating,
    isLoadingUsers,
    handleCreateSuborder,
    getClubMembershipByCustomer,
  } = useSuborder({ parentOrderId });

  const title = 'New Sub-Order';
  const goBack = () => navigate(-1);

  return (
    <ContentLayout
      title={title}
      processing={isCreating}
      leftAction={<BackButton component={Link} onClick={goBack} />}
    >
      <SuborderForm
        giftPackage={giftPackage}
        initialValues={initialValues}
        giftPackageOptions={giftPackageOptions}
        defaultClubMembership={defaultClubMembership}
        asyncSelectUsers={asyncSelectUsers}
        isLoadingUsers={isLoadingUsers}
        handleSubmit={handleCreateSuborder}
        getClubMembershipByCustomer={getClubMembershipByCustomer}
      />
    </ContentLayout>
  );
};
