import { useParams } from 'react-router-dom';
import { DiscountService } from 'App/api';
import { useLocations } from 'App/contexts';
import { useApiMutation, useApiQuery, useClubTier } from 'hooks';
import { CreateDiscount, formatDate } from 'shared';
import { getInitialValues } from '../helpers';

const key = 'Discount';

export const useEditDiscount = () => {
  const { id } = useParams();
  const { options: locationOptions, isLoading: isLoadingLocations } =
    useLocations();

  const { options: tiersOptions, isLoading: isLoadingTiers } = useClubTier({});

  const { data, isLoading } = useApiQuery([`get${key}`, id], () =>
    DiscountService.getOne({ id }),
  );

  const { mutate: update, isLoading: processing } = useApiMutation(
    [`update${key}`, id],
    DiscountService.update,
  );

  const handleSubmit = (data: CreateDiscount) => {
    const { start_date, end_date, ...rest } = data;

    update({
      id,
      data: {
        ...rest,
        start_date: formatDate(start_date),
        end_date: formatDate(end_date),
      },
    });
  };

  return {
    tiersOptions,
    locationOptions,
    processing,
    handleSubmit,
    initialValues: getInitialValues(data),
    isLoading: isLoading || isLoadingTiers || isLoadingLocations,
  };
};
