import { Button, FileButton, Flex, Grid } from '@mantine/core';
import { SetFieldValue } from '@mantine/form/lib/types';
import { IconDownload, IconUpload } from '@tabler/icons-react';

import { useUploadInvoice } from 'pages/orders/corporateOrders/hooks';
import { CorporateOrderPayload, DOWNLOAD_BTN, UPLOAD_BTN } from 'shared';

type Props = {
  urlInvoice?: string;
  setInvoice: SetFieldValue<CorporateOrderPayload>;
};

export const InvoiceButtons = ({ urlInvoice, setInvoice }: Props) => {
  const { setFile, isLoading } = useUploadInvoice({ setInvoice });

  return (
    <Grid.Col span={4} mt={28}>
      <Flex justify="flex-end" gap={2}>
        <FileButton onChange={setFile}>
          {(props) => (
            <Button
              {...props}
              variant="white"
              loading={isLoading}
              data-testid={UPLOAD_BTN}
              leftIcon={<IconUpload size={14} />}
            >
              Upload Invoice
            </Button>
          )}
        </FileButton>

        <Button
          component="a"
          variant="white"
          data-testid={DOWNLOAD_BTN}
          href={urlInvoice ?? ''}
          disabled={!urlInvoice}
          leftIcon={<IconDownload size={14} />}
        >
          Download
        </Button>
      </Flex>
    </Grid.Col>
  );
};
