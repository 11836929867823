import { AddressDto } from '../customer';
import { InventoryType } from '../inventory';
import { ShipmentCycleShortItem } from './shipmentCycle';
import { SelectOption } from '../common';

import { Modifier } from '../culinary';
import {
  Checkout,
  DeliveryMethod,
  FreightCompany,
  FreightMethod,
  Order,
  OrderChannel,
  OrderPaymentStatus,
  OrderSource,
  OrderStatus,
  SupplyType,
} from '../baseOrder';

export type LinkOrderOptions = {
  items?: {
    label: string;
    link: string;
    total: string;
  }[];
  grandTotal?: string;
};

export enum RefundType {
  FULL = 'full',
  ITEM_BASED = 'item_based',
  TRANSACTION_BASED = 'transaction_based',
}

export type RefundPayload = {
  refund_type: RefundType;
  payment?: {
    payment_id: string;
    items: RefundItemRequest[];
  };
};

export interface RefundItem
  extends Pick<InventoryDetail, 'sku_name' | 'price' | 'total'> {
  item_id?: string;
  item_quantity: number;
  initial_quantity: number;
  isSelected: boolean;
}

type StringDateNull = string | Date | null;

export const orderPaymentStatusLabels = {
  [OrderPaymentStatus.PAID]: 'Paid',
  [OrderPaymentStatus.PROCESSING_PAYMENT]: 'Processing Payment',
  [OrderPaymentStatus.PARTIALLY_PAID]: 'Partially Paid',
  [OrderPaymentStatus.UNPAID]: 'Unpaid',
  [OrderPaymentStatus.VOID]: 'Void',
  [OrderPaymentStatus.CANCEL]: 'Cancel',
  [OrderPaymentStatus.ERROR]: 'Error',
  [OrderPaymentStatus.REFUND]: 'Refunded',
  [OrderPaymentStatus.PARTIALLY_REFUND]: 'Partially Refund',
  [OrderPaymentStatus.THREE_D_SECURE_CONFIRMATION]:
    'Three D Secure confirmation',
};

export enum ServiceClass {
  wine_club = 'wine_club',
  regular = 'regular',
}

export type RefundItemRequest = {
  item_id?: string;
  item_quantity: number;
};

export type RefundedItem = {
  item_id: string;
  discount: number;
  sub_total: number;
  price: number;
  product_name: string;
  quantity: number;
  chosen_quantity: number;
  refunded_quantity: number;
  membership_discount: number;
  isSelected?: boolean;
};

export type RefundedItemCost = {
  items: RefundedItem[];
  items_full_price: number;
  shipping_cost: number;
  shipping_tax: number;
  total_taxes: number;
  total_fees: number;
  tips: number;
  total_promocode_discount: number;
  sub_total: number;
};

export enum OrderType {
  pos = 'pos',
  shipment = 'shipment',
  cycle = 'cycle',
}

export const labelOrder = {
  [OrderType.pos]: 'POS',
  [OrderType.shipment]: 'SHIPMENT',
  [OrderType.cycle]: 'ALICE',
};

export type ParamsShipmentSalesOrder = {
  service_class?: ServiceClass;
};
// TO DO: move to baseOrder type
export enum SalesOrderComplianceStatus {
  COMPLIANT = 'compliant',
  NOT_COMPLIANT = 'not_compliant',
  ERROR = 'error',
}

export const orderComplianceStatus: Record<SalesOrderComplianceStatus, string> =
  {
    [SalesOrderComplianceStatus.COMPLIANT]: 'Compliant',
    [SalesOrderComplianceStatus.NOT_COMPLIANT]: 'Not Compliant',
    [SalesOrderComplianceStatus.ERROR]: 'Error',
  };
// TO DO: move to baseOrder type
export const salesOrderStatusLabels: Record<OrderStatus, string> = {
  [OrderStatus.DRAFT]: 'Draft',
  [OrderStatus.OPEN]: 'Open',
  [OrderStatus.PROCESSING]: 'Processing',
  [OrderStatus.WAITING_SHIPPING]: 'Waiting Shipping',
  [OrderStatus.WAITING_PICKUP]: 'Waiting Pickup',
  [OrderStatus.SHIPPING]: 'Shipping',
  [OrderStatus.CLOSED]: 'Closed',
  [OrderStatus.PENDING_SHIPPING]: 'Pending Shipping',
  [OrderStatus.PENDING_BILLING]: 'Pending Billing',
  [OrderStatus.CANCEL]: 'Canceled',
  [OrderStatus.SKIPPED]: 'Skipped',
  [OrderStatus.PENDING_DELIVERY_METHOD_CHANGE]:
    'Pending Delivery Method Change',
  [OrderStatus.SUBORDERS_CREATION_IS_SCHEDULED]:
    'Suborders Creation is Scheduled',
  [OrderStatus.SUBORDERS_CREATION_IN_PROGRESS]:
    'Suborders Creation is Progress',
  [OrderStatus.SUBORDERS_CREATION_ERROR]: 'Suborders Creation Error',
  [OrderStatus.RECALCULATION_REQUIRED]: 'Recalculation Required',
  [OrderStatus.RECALCULATION_IS_SCHEDULED]: 'Recalculation is Scheduled',
  [OrderStatus.RECALCULATION_IN_PROGRESS]: 'Recalculation in Progress',
  [OrderStatus.RECALCULATION_ERROR]: 'Recalculation Error',
};

export const salesOrderChannelLabels: Record<OrderChannel, string> = {
  [OrderChannel.EXPERIENCES]: 'Experiences',
  [OrderChannel.WINE_CLUB]: 'Wine Club',
  [OrderChannel.REGULAR_SALES]: 'Regular Sales',
  [OrderChannel.CORPORATE]: 'Corporate',
};

export const salesOrderSourceTitle = {
  [OrderSource.ALICE]: 'Alice',
  [OrderSource.POS]: 'Pos',
  [OrderSource.ECOMMERCE]: 'Ecommerce',
};

export const supplyTypeLabels: Record<SupplyType, string> = {
  [SupplyType.FULFILLMENT]: 'Fulfillment',
  [SupplyType.PICKUP]: 'Pickup',
  [SupplyType.IN_HOUSE]: 'In House',
};

export interface AdvisorSchema {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  is_system: boolean;
}

export interface InventoryDetail {
  key?: string;
  id?: string;
  quantity: number;
  sku_id: string;
  sku_name: string;
  price: number;
  discount: number;
  sku_inventory_type?: InventoryType;
  total: number;
  payment_status?: OrderPaymentStatus;
  refunded_quantity?: number;
  promocode_data?: { id: string; name: string };
}

export type CorrectionOrder = {
  id: string;
  fee_correction: number;
  shipping_correction: number;
  tax_correction: number;
  total_due_correction: number;
};

export type RecalculateRequest = {
  delivery_method: DeliveryMethod;
  customer_shipping_address_id?: string;
};

export type PayRecalculateRequest = {
  delivery_method: DeliveryMethod;
  supply_type: SupplyType | null;
  deplete_location_id: string | null;
  deplete_inventory_location_id: string | null;
  customer_shipping_address_id: string | null;
  freight_company: FreightCompany | null;
  freight_method: FreightMethod | null;
  shipping_date: StringDateNull;
  customer_card_id: string | null;
  source: OrderSource;
};

export type RecalculateAndPayRequest = {
  delivery_method: DeliveryMethod;
  order_ids: string[];
};

export interface OrderDto {
  id: string;
  number: number;
  shipment_cycle: ShipmentCycleShortItem;
  date: Date;
  source: OrderSource;
  processed_in: OrderSource | null;
  total: number;
  customer: Order['customer'];
  payment_status: OrderPaymentStatus;
  status: OrderStatus;
  created_at: string;
  fully_paid_at: string;
  channel: OrderChannel;
  items: InventoryDetail[];
  delivery_method: DeliveryMethod;
  pick_up_deadline?: string;
  supply_type: SupplyType;
  freight_company?: FreightCompany;
  freight_method?: FreightMethod;
  shipping_cost?: number;
  shipping_date?: string;
  tracking_number?: string;
  billing_datetime?: string;
  re_billing_datetime?: string;
  has_shipping_data_diff?: boolean;
  updated_by: AdvisorSchema;
  compliance_status: SalesOrderComplianceStatus;
  service_class: ServiceClass;
  corrections: CorrectionOrder[];
  calculated_correction?: CorrectionOrder;
  is_zero_shipping_tax_fallback_triggered: boolean;
  customer_address_id: string | null;
  deplete_location?: {
    id: string;
    name: string;
    type: SupplyType;
  };
  deplete_inventory_location?: {
    id: string;
    name: string;
  };
  signature_url: string;
  shipping_address?: {
    first_name: string;
    last_name: string;
    address_line_1: string;
    address_line_2: string;
    country: string;
    state: string;
    city: string;
    business: string;
    zip_code: string;
    phone_number: string;
    email: string;
    date_of_birth: string;
  };
  card: {
    last4: string;
    brand: string;
    stripe_card_id: string;
  };
  checkout: Checkout | null;
}

export interface CycleOrderPayload {
  number?: number;
  createdAt?: string | null;
  customer: Order['customer'];
  processedIn: OrderSource | null;
  shipmentCycle?: ShipmentCycleShortItem;
  customer_id?: string;
  status: OrderStatus;
  items: InventoryDetail[];
  delivery_method: DeliveryMethod;
  channel: OrderChannel;
  supply_type?: SupplyType;
  payment_status: OrderPaymentStatus;
  customer_shipping_address_id?: string | null;
  deplete_location_id: string | null;
  deplete_inventory_location_id: string | null;
  freight_company?: FreightCompany | null;
  freight_method?: FreightMethod | null;
  shipping_date?: StringDateNull;
  tracking_number?: string | null;
  pick_up_deadline?: StringDateNull;
  billing_datetime?: StringDateNull;
  re_billing_datetime?: StringDateNull;
  shipment_cycle_id?: string;
  customer_card_id: string | null;
  updated_by_id: string;
  checkout: Checkout | null;
  is_skipped?: boolean;
  compliance_status: SalesOrderComplianceStatus;
  defaultWineAdvisor: SelectOption;
  promocode_id: string | null;
  customer_address_id?: string | null;
  defaultDiscount?: {
    value: string;
    label: string;
  };
  defaultCustomer?: {
    value: string;
    label: string;
  };
}

export interface CustomerCycleOrder {
  id: string;
  number: string;
  type: string;
  status: string;
  payment_status: OrderPaymentStatus;
  customer: {
    id: string;
    number: string;
    first_name: string;
    last_name: string;
  };
}

export interface CustomerOrderBody {
  address?: AddressDto;
  location?: {
    id?: string;
  };
  card?: {
    stripe_card_id?: string;
    brand?: string;
    last4?: string;
  };
}

export interface WalletSelectOption extends SelectOption {
  stripe_card_id?: string;
}

export interface CustomerSkippedOrder extends CustomerCycleOrder {
  shipment_cycle: ShipmentCycleShortItem;
  closed_at: string;
  updated_by: AdvisorSchema;
}

export interface CustomerSkippedOrders {
  items: CustomerSkippedOrder[];
  total: number;
}

export type OrderProductsTable = {
  id: string | undefined;
  quantity: string | number;
  refunded_quantity?: number;
  modifiers?: Modifier[];
  product_name: string;
  retail_price: string;
  discount_string: string;
  discounted_price: string;
  total_price: string;
};

export enum PaymentStatus {
  success = 'success',
  pending = 'pending',
  failed = 'failed',
  refunded = 'refunded',
  partially_refunded = 'partially_refunded',
}

export const paymentStatusValue = {
  [PaymentStatus.failed]: 'Error',
  [PaymentStatus.pending]: 'Pending',
};

export type ClubMemberShip = {
  bottleQty: number;
  text: string;
};
