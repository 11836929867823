import {
  DeliveryMethod,
  FreightCompany,
  FreightMethod,
  Order,
  OrderChannel,
  OrderItem,
  OrderPaymentStatus,
  OrderSource,
  OrderStatus,
  PaymentMethod,
  SupplyType,
} from '../baseOrder';
import { SelectOption } from '../common';
import { BillingAddress } from '../customer';
import { SalesOrderComplianceStatus } from '../membersClub';
import { SkuDto } from '../products';

type ShippingAddress = {
  first_name: string;
  last_name: string;
  date_of_birth: string;
  email: string;
  street_address_one: string;
  street_address_two: string;
  address_line_1: string;
  address_line_2: string;
  country: string;
  state: string;
  city: string;
  business: string;
  zip_code: string;
  phone_number: string;
};

export type CorporateOrderListDto = {
  id: string;
  number: number;
  status: OrderStatus;
  payment_status: OrderPaymentStatus;
  created_at: string;
  customer: Order['customer'];
  card: {
    last4: string;
    brand: string;
    stripe_card_id: string;
  };
  total: number;
  sub_orders: number;
};

export type GiftPackage = {
  id?: string;
  name: string;
  gift_package_items: {
    id?: string | null;
    key?: string;
    sku_id?: string;
    sku_name: string;
    quantity: number;
    sku?: SkuDto;
  }[];
};

export enum PromocodeStatus {
  APPLIED = 'applied',
  IN_PROGRESS = 'in_progress',
  NOT_APPLIED = 'not_applied',
  ERROR = 'error',
}

export interface CorporateOrderDto extends Order {
  errors: { id: string; message: string }[];
  billing_address: BillingAddress;
  gift_packages: GiftPackage[];
  recipients_file_url: string | null;
  promocode_data?: { id: string; name: string };
  promocode_status: PromocodeStatus;
  invoice_file: string;
  payment_method: PaymentMethod;
}

export interface SubOrderDto extends Order {
  gift_package_id: string;
  sku_prices_sum: number;
  sku_prices_discounts_sum: number;
  sku_discounted_prices_sum: number;
  deplete_location: { id: string };
  deplete_inventory_location: { id: string };
  shipping_address: ShippingAddress;
  customer_shipping_address_id: string | null;
}

export type CorporateOrderPayload = {
  id?: string;
  customer: Order['customer'];
  createdAt?: string | null;
  number?: number;
  status: OrderStatus;
  channel: OrderChannel;
  supply_type: SupplyType;
  payment_status: OrderPaymentStatus;
  delivery_method: DeliveryMethod;
  freight_company: FreightCompany;
  freight_method: FreightMethod;
  customer_id: string;
  customer_card_id: string | null;
  shipping_date: string | Date | null;
  gift_packages: GiftPackage[];
  updated_by_id?: string;
  invoice_file: string;
  recipients_file_url: string | null;
  processedIn: OrderSource | null;
  payment_method: PaymentMethod;
  defaultWineAdvisor: SelectOption;
};

export type SubOrderInitialValues = {
  status: OrderStatus;
  compliance_status: SalesOrderComplianceStatus;
  payment_status: OrderPaymentStatus;
  items: OrderItem[];
  channel: OrderChannel;
  delivery_method: DeliveryMethod;
  supply_type: SupplyType;
  customer_id: string | null;
  customer_shipping_address_id: string | null;
  freight_company: FreightCompany;
  freight_method: FreightMethod;
  shipping_date: string | Date | null;
  tracking_number: string;
  updated_by_id: string;
  deplete_location_id: string;
  defaultWineAdvisor: SelectOption;
  customer: Order['customer'];
  processedIn: OrderSource | null;
  gift_package_id: string;
  shipping_address: ShippingAddress;
};

export interface CorporateSubOrderList
  extends Omit<
    CorporateOrderListDto,
    'cards' | 'payment_status' | 'total' | 'sub_orders'
  > {
  gift_package: GiftPackage;
  compliance_status: SalesOrderComplianceStatus;
}

export type ProgressCorporateOrder = {
  corporate_gift_sales_order: {
    status: OrderStatus;
  };
  progress: {
    task: string;
    task_status: string;
    task_completion_percentage: number;
  };
};

export type PaymentByCheck = {
  check_number?: string;
  check_payment_date?: string | null;
  amount?: number | string;
};
