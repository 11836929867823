import { Container, SelectItem } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { discountSchema } from './schema';
import {
  AdditionalInformation,
  AdditionalSettings,
  ProductProfile,
  Toggles,
} from './formSections';
import { CreateDiscount, DiscountUnit, SelectOption } from 'shared';
import { getNewItem } from './helpers';
import { useInitialForm } from '../../hooks';
import { ActionButtons } from 'components';
import { useEffect } from 'react';

type Props = {
  options: SelectOption[];
  tiersOptions: SelectItem[];
  initialValues?: CreateDiscount;
  handleSubmit: (values: CreateDiscount) => void;
};

const mockValues: CreateDiscount = {
  name: '',
  is_active: false,
  value: 0,
  hide_from_ecommerce: false,
  description: '',
  comment: '',
  start_date: null,
  end_date: null,
  inventory_type: null,
  minimum_quantity: 0,
  minimum_total: 0,
  applied_to_glass: false,
  free_shipping: false,
  one_time_promotion: false,
  club_tier_id: null,
  unit: DiscountUnit.dollar,
  location_ids: [getNewItem()],
  category_id: null,
  subcategory_id: null,
};

export const Form = ({
  options,
  tiersOptions,
  initialValues,
  handleSubmit,
}: Props) => {
  const form = useForm({
    initialValues: initialValues ?? mockValues,
    validate: yupResolver(discountSchema),
    validateInputOnBlur: true,
  });
  const { values, setFieldValue } = form;
  const { categoryOptions, subcategoryOptions } = useInitialForm(
    values.category_id,
  );

  const hasSubcategories = !!values.category_id && !!subcategoryOptions.length;

  useEffect(() => {
    setFieldValue('hasSubcategories', hasSubcategories);
  }, [hasSubcategories, setFieldValue]);

  return (
    <Container size="xl" my="xl">
      <ProductProfile form={form} options={options} />
      <AdditionalSettings
        form={form}
        categoryOptions={categoryOptions}
        subcategoryOptions={subcategoryOptions}
      />
      <Toggles form={form} tiersOptions={tiersOptions} />
      <AdditionalInformation form={form} />
      {!initialValues && (
        <ActionButtons
          my={48}
          disabled={!form.isValid()}
          onClick={() => handleSubmit(form.values)}
          label="Save"
          aria-label="save"
        />
      )}
    </Container>
  );
};
