import request from '../../request';

import { CORPORATE_GIFT_SALES_ORDERS_PATH } from '../../endpoints';
import {
  CorporateOrderDto,
  CorporateOrderListDto,
  CorporateOrderPayload,
  CorporateSubOrderList,
  PaginatedRequest,
  PaginatedResponse,
  PaymentByCheck,
  ProgressCorporateOrder,
  SubOrderDto,
  SubOrderInitialValues,
} from 'shared';

export default class CorporateOrder {
  static readonly getCollection = (params: PaginatedRequest) => {
    return request<PaginatedResponse<CorporateOrderListDto>>({
      url: CORPORATE_GIFT_SALES_ORDERS_PATH,
      params,
      method: 'get',
    });
  };

  static readonly getSubCollection = ({
    id,
    params,
  }: {
    id?: string;
    params: PaginatedRequest;
  }) => {
    return request<PaginatedResponse<CorporateSubOrderList>>({
      url: `${CORPORATE_GIFT_SALES_ORDERS_PATH}/${id}/sub-orders`,
      params,
      method: 'get',
    });
  };

  static readonly getOne = (id: string) => {
    return request<CorporateOrderDto>({
      url: `${CORPORATE_GIFT_SALES_ORDERS_PATH}/${id}`,
      method: 'get',
    });
  };

  static readonly getOneSubOrder = ({
    parentOrderId,
    subOrderId,
  }: {
    parentOrderId?: string;
    subOrderId?: string;
  }) => {
    return request<SubOrderDto>({
      url: `${CORPORATE_GIFT_SALES_ORDERS_PATH}/${parentOrderId}/suborders/${subOrderId}`,
      method: 'get',
    });
  };

  static readonly editSubOrder = ({
    parentOrderId,
    subOrderId,
    data,
  }: {
    parentOrderId?: string;
    subOrderId?: string;
    data: SubOrderInitialValues;
  }) => {
    return request<SubOrderDto>({
      url: `${CORPORATE_GIFT_SALES_ORDERS_PATH}/${parentOrderId}/suborders/${subOrderId}`,
      method: 'put',
      data,
    });
  };

  static readonly create = (data: CorporateOrderPayload) => {
    return request<CorporateOrderDto>({
      url: CORPORATE_GIFT_SALES_ORDERS_PATH,
      method: 'post',
      data,
    });
  };

  static readonly generateS3PreSignedURL = ({
    documentName,
  }: {
    documentName: string;
  }) => {
    return request<{ url: string }>({
      url: `${CORPORATE_GIFT_SALES_ORDERS_PATH}/recipients-file-upsert-url`,
      method: 'post',
      data: { document_name: documentName },
    });
  };

  static readonly createSuborders = ({ id }: { id?: string }) => {
    return request({
      url: `${CORPORATE_GIFT_SALES_ORDERS_PATH}/${id}/create-suborders`,
      method: 'post',
    });
  };

  static readonly deleteSuborder = ({
    id,
    parentOrderId,
  }: {
    id?: string;
    parentOrderId?: string;
  }) => {
    return request({
      url: `${CORPORATE_GIFT_SALES_ORDERS_PATH}/${parentOrderId}/suborders/${id}`,
      method: 'delete',
    });
  };

  static readonly createSuborder = ({
    parentOrderId,
    data,
  }: {
    parentOrderId?: string;
    data: SubOrderInitialValues;
  }) => {
    return request({
      url: `${CORPORATE_GIFT_SALES_ORDERS_PATH}/${parentOrderId}/suborders`,
      method: 'post',
      data,
    });
  };

  static readonly addRemovePromocode = ({
    orderId,
    promocodeId,
  }: {
    orderId?: string;
    promocodeId: string | null;
  }) => {
    return request({
      url: `${CORPORATE_GIFT_SALES_ORDERS_PATH}/${orderId}/add-remove-promocode`,
      method: 'post',
      params: promocodeId ? { promocode_id: promocodeId } : {},
    });
  };

  static readonly confirmAndPay = ({
    id,
    data,
  }: {
    id?: string;
    data?: PaymentByCheck;
  }) => {
    return request({
      url: `${CORPORATE_GIFT_SALES_ORDERS_PATH}/${id}/confirm-and-pay`,
      method: 'post',
      data,
    });
  };

  static readonly delete = ({ id }: { id?: string }) => {
    return request({
      url: `${CORPORATE_GIFT_SALES_ORDERS_PATH}/${id}/cancel-creation-of-suborders`,
      method: 'post',
    });
  };

  static readonly getProgress = ({ id }: { id: string | null }) => {
    return request<ProgressCorporateOrder>({
      url: `${CORPORATE_GIFT_SALES_ORDERS_PATH}/${id}/create-suborders/progress`,
      method: 'get',
    });
  };

  static readonly update = ({
    id,
    data,
  }: {
    id?: string;
    data: CorporateOrderPayload;
  }) => {
    return request<CorporateOrderDto>({
      url: `${CORPORATE_GIFT_SALES_ORDERS_PATH}/${id}`,
      method: 'put',
      data,
    });
  };
}
