import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CorporateOrder, OrdersService, PaymentsService } from 'App/api';
import {
  useApiMutation,
  useApiQuery,
  usePrivateDocument,
  useToast,
  useUserCards,
} from 'hooks';
import {
  capitalizeText,
  CorporateOrderPayload,
  DATE_FORMAT_DOT,
  formatDate,
  getCheckoutSummary,
  getClubMembership,
  getCurrencyString,
  getEmptyOption,
  getFullName,
  getSelectOptions,
  PaymentByCheck,
  PaymentMethod,
  PaymentsDto,
  PromocodeStatus,
  RefundType,
  SelectOption,
} from 'shared';
import { getInitialValues, getPromocodeAppliedMessage } from '../helper';

const getPaymentByCheck = (payments: PaymentsDto['items']) => {
  const { amount, check_number, check_payment_date } =
    payments.find((payment) => payment.method === PaymentMethod.check) ?? {};

  if (amount && check_number && check_payment_date) {
    return {
      amount: getCurrencyString(amount),
      check_number,
      check_payment_date: formatDate(check_payment_date, DATE_FORMAT_DOT),
    };
  }
};

const key = 'corporateOrder';

export const useEditCorporateOrder = () => {
  const [promocodeStatus, setPromocodeStatus] =
    useState<PromocodeStatus | null>(null);

  const [paymentByCheckData, setPaymentByCheckData] =
    useState<PaymentByCheck | null>(null);

  const { id = '' } = useParams();
  const addToast = useToast();

  const {
    isLoading: isLoadingInvoice,
    document: invoice,
    getDocument,
  } = usePrivateDocument();
  const { url: urlInvoice } = invoice ?? {};

  const {
    data: payment,
    isLoading: isLoadingPayments,
    refetch: refetchPayment,
  } = useApiQuery([`${key}Payment`, id], () =>
    PaymentsService.getCollection(id),
  );

  const {
    data,
    isLoading,
    isFetching,
    refetch: refetchOrder,
  } = useApiQuery([`${key}GetOne`, id], () => CorporateOrder.getOne(id), {
    onSuccess: ({ promocode_status }) => setPromocodeStatus(promocode_status),
    refetchInterval: promocodeStatus === PromocodeStatus.IN_PROGRESS && 120000,
  });

  const { mutate: updateCorporateOrder, isLoading: isUpdating } =
    useApiMutation([`${key}Update`, id], CorporateOrder.update, {
      onSuccess: () => {
        addToast.success('The order has been updated!');
        refetchOrder();
      },
    });

  const { mutate: confirmAndPay, isLoading: isPaying } = useApiMutation(
    [`${key}ConfirmAndPay`, id],
    CorporateOrder.confirmAndPay,
    {
      onSuccess: () => {
        addToast.success('Payment has been added to the schedule!');
        refetchOrder();
        refetchPayment();
      },
    },
  );

  const { mutate: deleteOrder, isLoading: isDeleting } = useApiMutation(
    [`${key}Delete`, id],
    CorporateOrder.delete,
    {
      onSuccess: () => {
        addToast.success('Order has been deleted!');
        refetchOrder();
      },
    },
  );

  const { mutateAsync: refund, isLoading: isRefunding } = useApiMutation(
    [`${key}Refund`, id],
    OrdersService.refund,
    {
      onSuccess: () => {
        refetchOrder();
        refetchPayment();

        addToast.success('Refund has been completed!');
      },
    },
  );

  const { mutate: deleteSubOrder, isLoading: isDeletingSubOrder } =
    useApiMutation(
      [`${key}DeleteSubOrder`, id],
      CorporateOrder.deleteSuborder,
      {
        onSuccess: () => {
          addToast.success('Sub Order has been deleted!');
          refetchOrder();
        },
      },
    );

  const {
    number,
    status,
    checkouts,
    errors,
    invoice_file,
    promocode_data,
    promocode_status,
    customer: { id: customerId, first_name, last_name, phone_number },
  } = data ?? { customer: {} };

  const { data: cards, isLoading: isLoadingCards } = useUserCards({
    id: customerId,
  });

  const defaultCardId = useMemo(() => {
    const { id } =
      cards?.items.find(
        ({ stripe_card_id }) => stripe_card_id === data?.card?.stripe_card_id,
      ) ?? {};

    return id;
  }, [cards?.items, data?.card?.stripe_card_id]);

  const initialValues = useMemo(
    () => getInitialValues({ data, defaultCardId }),
    [data, defaultCardId],
  );

  const initialStep = useMemo(
    () => (data?.recipients_file_url ? 1 : 0),
    [data],
  );

  const defaultCustomer = {
    value: customerId ?? '',
    label: `${getFullName({ first_name, last_name })} ${phone_number}`,
  };

  const defaultClubMembership = useMemo(
    () =>
      getClubMembership({
        mainTier: data?.customer.main_tier,
        addOnTier: data?.customer.add_on_tier,
      }),
    [data],
  );

  const userCards = useMemo(() => {
    const cardSelectOptions = cards?.items.map(
      ({ id, card_brand, card_last_4 }) => ({
        id,
        name: `${capitalizeText(card_brand)} **** **** **** ${card_last_4}`,
      }),
    );

    const check = getEmptyOption('Check', PaymentMethod.check);

    const options =
      (cardSelectOptions && getSelectOptions([...cardSelectOptions])) ?? [];

    return [...options, check];
  }, [cards]);

  const summary = useMemo(
    () => checkouts?.[0] && getCheckoutSummary(checkouts[0]),
    [checkouts],
  )?.filter((i) => i[0] !== 'Gratuity' && i[0] !== 'Member’s Discount');

  const handleUpdateOrder = (data: CorporateOrderPayload) => {
    const { payment_method, customer_card_id } = data;
    const isCheck = payment_method === PaymentMethod.check;

    const shipping_date = formatDate(data.shipping_date);
    updateCorporateOrder({
      id,
      data: {
        ...data,
        shipping_date,
        customer_card_id: isCheck ? null : customer_card_id,
      },
    });
  };

  const handleConfirmAndPay = () => {
    confirmAndPay({
      id,
      ...(paymentByCheckData && {
        data: {
          ...paymentByCheckData,
          check_payment_date:
            formatDate(paymentByCheckData.check_payment_date) ?? '',
        },
      }),
    });
  };
  const handleDelete = () => deleteOrder({ id });

  const handleRefund = () =>
    refund({ id, data: { refund_type: RefundType.FULL } });

  const handleDeleteSubOrder = ({
    id,
    parentOrderId,
  }: {
    id: string;
    parentOrderId?: string;
  }) => deleteSubOrder({ id, parentOrderId });

  const promocodeAppliedMessage = getPromocodeAppliedMessage(promocode_status);
  const isProcessingApplied = promocode_status === PromocodeStatus.IN_PROGRESS;

  const defaultPromocode = {
    value: promocode_data?.id ?? '',
    label: promocode_data?.name ?? 'No Promo Code',
  } as SelectOption;

  const threeDSecureUrl = payment?.items.find(
    ({ three_d_secure_url }) => three_d_secure_url,
  )?.three_d_secure_url;

  const paymentByCheck = payment?.items && getPaymentByCheck(payment.items);

  useEffect(() => {
    if (invoice_file) {
      getDocument(invoice_file);
    }
  }, [getDocument, invoice_file]);

  return {
    status,
    number,
    urlInvoice,
    defaultCustomer,
    initialStep,
    initialValues,
    paymentByCheck,
    userCards,
    summary,
    errors,
    threeDSecureUrl,
    defaultClubMembership,
    promocodeAppliedMessage,
    defaultPromocode,
    orderTotalPrice: checkouts && getCurrencyString(checkouts[0]?.total ?? ''),
    isPaying,
    isRefunding,
    isDeleting,
    isProcessingApplied,
    isProcessing: isDeletingSubOrder || isUpdating,
    isLoading:
      isLoading ||
      isFetching ||
      isLoadingCards ||
      isLoadingPayments ||
      isLoadingInvoice,
    setPaymentByCheckData,
    refetchOrder,
    handleUpdateOrder,
    handleDelete,
    handleRefund,
    handleConfirmAndPay,
    handleDeleteSubOrder,
  };
};
