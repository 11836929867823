import { AddressDto, CustomerDto, dateInputOutputFormat } from 'shared';
import { addressInitialValues } from './data';

export const normalizeAddresses = (addresses?: AddressDto[]) => {
  if (!addresses) return [];

  return addresses?.map((address) => {
    const { date_of_birth } = address;

    return {
      ...address,
      date_of_birth: dateInputOutputFormat(date_of_birth as string, 'input'),
    };
  });
};

export const getInitialAddressValue = (
  profileData?: CustomerDto['profile'],
) => {
  const { first_name, last_name, email, phone_number, date_of_birth } =
    profileData ?? {};

  return {
    ...addressInitialValues,
    first_name,
    last_name,
    email,
    phone_number,
    date_of_birth: dateInputOutputFormat(date_of_birth as string, 'input'),
  } as AddressDto;
};
