import { useNavigate } from 'react-router-dom';
import { SingleValue } from 'react-select';
import { Button, Grid, Select, Text } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import {
  salesOrderSourceTitle,
  HALTER_RANCH_LOCATION_ID,
  orderPaymentStatusLabels,
  salesOrderStatusLabels,
  SelectOption,
  channelOrderOptions,
  getTabEditPath,
  ClubMemberShip,
  salesOrderChannelLabels,
  OrderChannel,
  OrderStatus,
  GeneralInfoProps,
  AccountGroup,
  UPDATED_BY_DROPDOWN,
  CHANNEL_DROPDOWN,
  SHIPMENT_CYCLE_DROPDOWN,
} from 'shared';
import { useEmployees, useShipmentCycleOptions } from 'hooks';
import { SelectAsync } from 'components/selectAsync';
import { generateRoutePath, RouteName } from 'App/routes';

type Props<T> = {
  form: UseFormReturnType<T>;
  clubMemberShip: ClubMemberShip | null;
  selectCustomer?: JSX.Element;
  isRefunded?: boolean;
};

export const GeneralInfo = <T extends GeneralInfoProps>({
  form,
  clubMemberShip,
  selectCustomer,
  isRefunded = false,
}: Props<T>) => {
  const navigate = useNavigate();
  const {
    values: {
      channel,
      status,
      payment_status,
      defaultWineAdvisor,
      shipmentCycle,
      number,
      createdAt,
      customer,
      processedIn,
    },
  } = form;

  const isOpen = status === OrderStatus.OPEN;

  const isWineClub = channel === OrderChannel.WINE_CLUB;

  const isUnregistered =
    customer?.account_group === AccountGroup.UNREGISTERED_CUSTOMER;

  const {
    employeesOptions,
    isLoading,
    hasMore,
    setSearchStringSelectAsync,
    setPageSelectAsync,
  } = useEmployees({
    isSelectAsync: true,
    locationId: HALTER_RANCH_LOCATION_ID,
    include_system: true,
    page_size: 50,
    noCache: true,
  });

  const {
    shipmentCycleOptions,
    isLoading: isLoadingShipmentCycle,
    hasMore: hasMoreShipmentCycle,
    setPage: setShipmentCyclePage,
    updateFilters: updateFiltersShipmentCycle,
  } = useShipmentCycleOptions({
    enabled: isWineClub,
  });

  const defaultCycleName = {
    label: shipmentCycle?.name,
    value: shipmentCycle?.id,
  };

  const customerPath = generateRoutePath({
    name: RouteName.Customers,
  });

  const customerProfilePath =
    customer?.id && getTabEditPath(customerPath, customer?.id, 'profile');

  const customerField = selectCustomer ?? (
    <>
      <Text weight={600} mb="sm">
        Customer Name
      </Text>
      {isUnregistered ? (
        <Text>
          {customer?.first_name} {customer?.last_name}
        </Text>
      ) : (
        <Button
          p={0}
          variant="white"
          data-testid="customer-name-btn"
          onClick={() => navigate(customerProfilePath ?? '')}
        >
          {customer?.first_name} {customer?.last_name}
        </Button>
      )}
    </>
  );

  return (
    <Grid mb="xl" gutter="xl">
      <Grid.Col mb="xs">
        <Text size="lg" weight={600}>
          General Information
        </Text>
      </Grid.Col>
      <Grid.Col span={3}>
        <Text weight={600} mb="sm">
          Order #
        </Text>
        <Text>{number}</Text>
      </Grid.Col>
      <Grid.Col span={3}>
        <Text weight={600} mb="sm">
          Creation Date
        </Text>
        <Text>{createdAt}</Text>
      </Grid.Col>
      <Grid.Col span={3}>
        <Text weight={600} mb="sm">
          Order Status
        </Text>
        <Text>{status ? salesOrderStatusLabels[status] : '-'}</Text>
      </Grid.Col>
      <Grid.Col span={3}>
        <Text weight={600} mb="sm">
          Payment Status
        </Text>
        <Text>
          {payment_status ? orderPaymentStatusLabels[payment_status] : '-'}
        </Text>
      </Grid.Col>
      <Grid.Col span={3}>
        {!isRefunded ? (
          <SelectAsync
            required
            isSearchable
            searchByString={({ search_string }) =>
              setSearchStringSelectAsync(search_string)
            }
            label="Updated By"
            data-testid={UPDATED_BY_DROPDOWN}
            setPage={setPageSelectAsync}
            hasMore={hasMore}
            isLoading={isLoading}
            options={employeesOptions}
            {...form.getInputProps('updated_by_id')}
            defaultValue={defaultWineAdvisor}
            onChange={(item: SingleValue<SelectOption>) => {
              if (item?.value) {
                const value = item.value as 'updated_by_id' extends keyof T
                  ? T[keyof T & 'updated_by_id']
                  : unknown;

                form.setFieldValue('updated_by_id', value);
              }
            }}
          />
        ) : (
          <>
            <Text weight={600} mb="sm">
              Updated By
            </Text>
            <Text>{defaultWineAdvisor?.label}</Text>
          </>
        )}
      </Grid.Col>
      <Grid.Col span={3}>
        <Text weight={600} mb="sm">
          Processed in
        </Text>
        <Text>{processedIn ? salesOrderSourceTitle[processedIn] : '-'}</Text>
      </Grid.Col>

      <Grid.Col span={3} />
      <Grid.Col span={3} />

      <Grid.Col span={3}>{customerField}</Grid.Col>
      <Grid.Col span={3}>
        <Text weight={600} mb="sm">
          Club Membership
        </Text>
        <Text>{clubMemberShip?.text ?? '-'}</Text>
      </Grid.Col>

      <Grid.Col span={3} />
      <Grid.Col span={3} />

      <Grid.Col span={3}>
        {isOpen || !status ? (
          <>
            <Text weight={600} mb="sm">
              Channel
            </Text>
            <Text>{salesOrderChannelLabels[channel]}</Text>
          </>
        ) : (
          <Select
            required
            width="100%"
            label="Channel"
            data-testid={CHANNEL_DROPDOWN}
            data={channelOrderOptions}
            {...form.getInputProps('channel')}
          />
        )}
      </Grid.Col>
      <Grid.Col span={3}>
        {isWineClub && (
          <SelectAsync
            required
            isSearchable
            label="Cycle Name"
            data-testid={SHIPMENT_CYCLE_DROPDOWN}
            searchByString={updateFiltersShipmentCycle}
            setPage={setShipmentCyclePage}
            hasMore={hasMoreShipmentCycle}
            isLoading={isLoadingShipmentCycle}
            options={shipmentCycleOptions}
            defaultValue={defaultCycleName}
            {...form.getInputProps(`shipment_cycle_id`)}
            onChange={(item: SingleValue<SelectOption>) => {
              if (item?.value) {
                const value = item.value as 'shipment_cycle_id' extends keyof T
                  ? T[keyof T & 'shipment_cycle_id']
                  : unknown;

                form.setFieldValue('shipment_cycle_id', value);
              }
            }}
          />
        )}
      </Grid.Col>
    </Grid>
  );
};
