import {
  DATE_FORMAT_SLASH,
  formatDate,
  OrderChannel,
  OrderSource,
  SupplyType,
} from 'shared';

export const suborderInitialValues = {
  payment_status: null,
  customer_id: '',
  status: null,
  number: 'Will be created after saving',
  createdAt: formatDate(new Date(), DATE_FORMAT_SLASH),
  processedIn: OrderSource.ALICE,
  channel: OrderChannel.CORPORATE,
  updated_by_id: '',
  supply_type: SupplyType.FULFILLMENT,
  deplete_location_id: '',
  deplete_inventory_location_id: '',
  pick_up_deadline: null,
  pick_up_location_id: null,
  customer_shipping_address_id: '',
  freight_company: 'ups',
  freight_method: 'ground',
  shipping_date: null,
  tracking_number: '',
  items: [],
  shipping_address: {
    first_name: '',
    last_name: '',
    date_of_birth: '',
    email: '',
    address_line_1: '',
    address_line_2: '',
    country: '',
    state: '',
    city: '',
    business: '',
    zip_code: '',
    phone_number: '',
  },
};
