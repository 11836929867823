import { Grid, Select, SelectItem, Switch } from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { CreateDiscount, SWITCH_GRID_TOP_OFFSET } from 'shared';

type Props = {
  form: UseFormReturnType<CreateDiscount>;
  tiersOptions: SelectItem[];
};

export const Toggles = ({ form, tiersOptions }: Props) => {
  const { is_club_tier, club_tier_id } = form.values;
  return (
    <Grid gutter="xl">
      <Grid.Col pt={SWITCH_GRID_TOP_OFFSET}>
        <Switch
          label="Applies to Glass"
          labelPosition="right"
          sx={{ fontWeight: 600 }}
          {...form.getInputProps('applied_to_glass', { type: 'checkbox' })}
        />
      </Grid.Col>
      <Grid.Col pt={SWITCH_GRID_TOP_OFFSET}>
        <Switch
          label="Free Shipping"
          labelPosition="right"
          sx={{ fontWeight: 600 }}
          {...form.getInputProps('free_shipping', { type: 'checkbox' })}
        />
      </Grid.Col>
      <Grid.Col pt={SWITCH_GRID_TOP_OFFSET}>
        <Switch
          label="One Time Promotion"
          labelPosition="right"
          sx={{ fontWeight: 600 }}
          {...form.getInputProps('one_time_promotion', { type: 'checkbox' })}
        />
      </Grid.Col>
      <Grid.Col span={6} pt={SWITCH_GRID_TOP_OFFSET}>
        <Switch
          label="Club Members Only"
          labelPosition="right"
          sx={{ fontWeight: 600 }}
          {...form.getInputProps('is_club_tier', { type: 'checkbox' })}
          onChange={({ currentTarget: { checked } }) => {
            form.setFieldValue('is_club_tier', checked);
            form.setFieldValue('club_tier_id', null);
          }}
        />
      </Grid.Col>
      <Grid.Col span={6} sx={{ height: 87 }}>
        {is_club_tier && (
          <Select
            required={is_club_tier}
            clearable
            value={club_tier_id}
            data={tiersOptions}
            label="Wine Club Tier"
            {...form.getInputProps('club_tier_id')}
          />
        )}
      </Grid.Col>
    </Grid>
  );
};
