import { generateRoutePath, RouteName } from 'App/routes';
import { Create, Edit, Suborder, List } from './components';
import { NestedRouteComponent } from 'components';
import { usePermissions } from 'hooks';
import { NestedRoutes, Permissions } from 'shared';

export const CorporateOrders = () => {
  const { checkAccess } = usePermissions();
  const rootPath = generateRoutePath({
    name: RouteName.CorporateOrders,
  });

  const canEdit = checkAccess(
    Permissions.admin_panel_corporate_gift_sales_order_edit,
  );
  const canCreate = checkAccess(
    Permissions.admin_panel_corporate_gift_sales_order_create,
  );

  const ListComponent = <List rootPath={rootPath} />;
  const EditComponent = <Edit rootPath={rootPath} />;
  const EditSuborderComponent = <Suborder />;
  const CreateComponent = <Create rootPath={rootPath} />;

  return (
    <NestedRouteComponent
      showExtraRoute
      showEdit={canEdit}
      showCreate={canCreate}
      ListComponent={ListComponent}
      Edit={EditComponent}
      Create={CreateComponent}
      extraRoute={{
        path: NestedRoutes.SubOrder,
        element: EditSuborderComponent,
      }}
      rootPath={rootPath}
    />
  );
};
