import { Grid, Select } from '@mantine/core';

import { SearchInput } from 'components';
import { ReactNode } from 'react';
import {
  getEmptyOption,
  orderPaymentStatusOptions,
  OrderStatus,
  salesOrderStatusOptions,
} from 'shared';

interface Props {
  handleChange: (v: { [key: string]: string | string[] }) => void;
  isSelectedBulk: boolean;
  values: {
    search_string: string;
    statuses: string[];
    payment_statuses: string[];
  };
  children: ReactNode;
}

export const Filters = ({
  handleChange,
  values,
  isSelectedBulk,
  children,
}: Props): JSX.Element => {
  const { search_string, statuses, payment_statuses } = values;

  const withoutSuborderStatuses = salesOrderStatusOptions.filter(
    ({ value }) =>
      ![
        OrderStatus.SUBORDERS_CREATION_ERROR,
        OrderStatus.SUBORDERS_CREATION_IN_PROGRESS,
        OrderStatus.SUBORDERS_CREATION_IS_SCHEDULED,
      ].includes(value),
  );

  return (
    <Grid mb="md" justify="space-between">
      <Grid.Col span={2}>
        <Select
          label="Status"
          value={statuses[0] ?? ''}
          searchable
          disabled={isSelectedBulk}
          nothingFound="No options"
          data={[getEmptyOption('All'), ...withoutSuborderStatuses]}
          onChange={(v) => handleChange({ statuses: v ?? '' })}
        />
      </Grid.Col>
      <Grid.Col span={2}>
        <Select
          label="Payment Status"
          value={payment_statuses[0] ?? ''}
          searchable
          disabled={isSelectedBulk}
          nothingFound="No options"
          data={[getEmptyOption('All'), ...orderPaymentStatusOptions]}
          onChange={(v) => handleChange({ payment_statuses: v ?? '' })}
        />
      </Grid.Col>
      <Grid.Col span={2} sx={{ alignItems: 'center', marginTop: 24 }}>
        {children}
      </Grid.Col>

      <Grid.Col span={6}>
        <SearchInput
          handleSubmit={(v: string) => handleChange({ search_string: v })}
          label="Search"
          value={search_string}
        />
      </Grid.Col>
    </Grid>
  );
};
