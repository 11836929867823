import request from '../../request';
import { PaymentsDto, SendReceiptPayload } from 'shared';
import { PAYMENTS } from '../../endpoints';

export default class PaymentsService {
  static readonly getCollection = (order_id: string) => {
    return request<PaymentsDto>({
      url: PAYMENTS,
      method: 'get',
      params: { order_id, ordering: 'asc' },
    });
  };

  static readonly refund = ({ id }: { id: string }) => {
    return request({
      url: `${PAYMENTS}/${id}/refund`,
      data: {
        refund_type: 'transaction_based',
        applied_for: [],
      },
      method: 'post',
    });
  };

  static readonly regenerateReceipt = ({ id }: { id: string }) => {
    return request({
      url: `${PAYMENTS}/${id}/regenerate-receipt`,
      method: 'post',
    });
  };

  static readonly sendReceipt = ({
    id,
    data,
  }: {
    id?: string;
    data: SendReceiptPayload;
  }) => {
    return request({
      url: `${PAYMENTS}/${id}/send-receipt`,
      method: 'post',
      data,
    });
  };
}
