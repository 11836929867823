import * as yup from 'yup';

import { passwordRegex, zipCodeRegex, phoneNumberRegex } from './regex';
import dayjs from 'dayjs';

export const requiredStringValidationRule = (fieldName = 'Field') =>
  yup
    .string()
    .required(`${fieldName} is required`)
    .trim(`${fieldName} must be a trimmed string`)
    .strict(true);

export const requiredDateValidationRule = (fieldName = 'Field') =>
  yup.date().required(`${fieldName} is required`);

export const emailValidationRule = requiredStringValidationRule('Email').email(
  'Please enter valid email address',
);

export const passwordValidationRule = requiredStringValidationRule('Password');

export const createPasswordValidator = yup
  .string()
  .required('Please Enter New Password')
  .min(8, 'Must be at least 8 characters')
  .matches(passwordRegex, 'Password does not meet the requirements.');

export const zipCodeValidator = yup
  .string()
  .required('Please Enter Zip Code')
  .matches(zipCodeRegex, 'Invalid Zip Code.');

export const phoneNumberValidator = yup
  .string()
  .required('Please Enter Phone Number')
  .matches(phoneNumberRegex, 'Invalid Phone Number.');

export const requiredNumberValidationRule = (fieldName = 'Field') =>
  yup
    .number()
    .positive(`${fieldName} must be a positive number`)
    .required(`${fieldName} is required`);

export const dependentStringValidationRule = ({
  dependencyFormAttribute,
  dependencyValue,
  dependentFieldName,
}: {
  dependencyFormAttribute: string;
  dependencyValue: boolean;
  dependentFieldName: string;
}) =>
  yup
    .string()
    .notRequired()
    .when(`${dependencyFormAttribute}`, {
      is: dependencyValue,
      then: () => yup.string().required(`${dependentFieldName} is required`),
    });

export const dateOfBirthValidator = yup
  .string()
  .nullable()
  .test('Is date greater', 'Age must be 21 years old or more', (value) => {
    const currentDate = dayjs(new Date());
    if (!value) return true;
    return dayjs(currentDate.diff(value)).year() - 1970 >= 21;
  })
  .matches(/^(0[1-9]|1[0-2]).(0[1-9]|[12]\d|3[01]).(19|20)\d{2}$/, {
    excludeEmptyString: true,
    message: 'Incorrect date of birth',
  });
