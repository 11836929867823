import { randomId } from '@mantine/hooks';

import {
  CorporateOrderDto,
  CorporateOrderListDto,
  CorporateOrderPayload,
  CorporateSubOrderList,
  DATE_FORMAT_SLASH,
  formatDate,
  GeneralInfoProps,
  getClubMembership,
  getDate,
  getDateSOutput,
  getFullName,
  orderComplianceStatus,
  OrderGeneralInformation,
  PaymentMethod,
  PromocodeStatus,
  salesOrderStatusLabels,
  SubOrderDto,
  SubOrderInitialValues,
} from 'shared';
import { suborderInitialValues } from '../constants/mockValues';

export const getNewProduct = () => ({
  key: randomId(),
  quantity: 0,
});

export const getNewPackage = (index = 1) => ({
  name: `Gift Package ${index}`,
  gift_package_items: [getNewProduct()],
});

export const normalizeCorporateOrdersList = (items: CorporateOrderListDto[]) =>
  items.map(
    ({ id, number, customer, created_at, total, sub_orders, status, card }) => {
      const { last4 } = card ?? {};
      const { first_name, last_name } = customer;
      const fullName = getFullName({ first_name, last_name });
      const createdAt = getDateSOutput(created_at);

      return {
        id,
        number,
        createdAt,
        total,
        last4,
        customer: fullName,
        subOrders: sub_orders,
        status: salesOrderStatusLabels[status],
      };
    },
  );

export const normalizeCorporateSubOrdersList = (
  items: CorporateSubOrderList[],
) =>
  items.map(({ id, number, customer, gift_package, compliance_status }) => {
    const { first_name, last_name, add_on_tier, main_tier } = customer;
    const choice = gift_package?.name || '-';

    const complianceCheck = orderComplianceStatus[compliance_status];
    const recipientName = getFullName({ first_name, last_name });
    const membership = getClubMembership({
      mainTier: main_tier,
      addOnTier: add_on_tier,
    }).text;

    return {
      id,
      number,
      recipientName,
      membership,
      choice,
      complianceCheck,
      compliance_status,
    };
  });

export const getGeneralInformation = ({
  number,
  status,
  channel,
  processed_in,
  payment_status,
  updated_by,
  created_at,
  customer,
}: OrderGeneralInformation): GeneralInfoProps => {
  const { id: idWineAdvisor, first_name, last_name } = updated_by ?? {};

  const defaultWineAdvisor = {
    value: idWineAdvisor,
    label: getFullName({ first_name, last_name }),
  };

  const createdAt = formatDate(created_at, DATE_FORMAT_SLASH);

  return {
    status,
    number,
    createdAt,
    customer,
    channel,
    payment_status,
    defaultWineAdvisor,
    updated_by_id: updated_by.id,
    processedIn: processed_in,
  };
};

export const getInitialValues = ({
  data,
  defaultCardId,
}: {
  data?: CorporateOrderDto;
  defaultCardId?: string;
}): CorporateOrderPayload | undefined => {
  if (!data) return;

  const { gift_packages, shipping_date, payment_method } = data;

  const generalData = getGeneralInformation(data);

  const giftPackages = gift_packages.map(({ gift_package_items, ...rest }) => {
    return {
      ...rest,
      gift_package_items: gift_package_items.map(({ id, sku, quantity }) => ({
        quantity,
        id: id ?? null,
        sku_id: sku?.id,
        sku_name: `[${sku?.sku_number}] ${sku?.product_name}`,
      })),
    };
  });

  const isCheck = payment_method === PaymentMethod.check;

  return {
    ...data,
    ...generalData,
    gift_packages: giftPackages,
    customer_id: generalData.customer?.id,
    shipping_date: getDate(shipping_date),
    customer_card_id: isCheck ? PaymentMethod.check : defaultCardId ?? '',
  };
};

export const getSubOrderInitialValues = (
  subOrder?: SubOrderDto,
): SubOrderInitialValues => {
  if (!subOrder) {
    return suborderInitialValues as unknown as SubOrderInitialValues;
  } else {
    const {
      customer,
      shipping_address,
      deplete_location,
      deplete_inventory_location,
      shipping_date,
    } = subOrder ?? {};
    const generalData = getGeneralInformation(subOrder);

    return {
      ...subOrder,
      ...generalData,
      customer_id: customer?.id,
      shipping_date: getDate(shipping_date),
      deplete_location_id: deplete_location?.id,
      deplete_inventory_location_id: deplete_inventory_location?.id,
      shipping_address: {
        ...shipping_address,
        date_of_birth: formatDate(
          shipping_address.date_of_birth,
          DATE_FORMAT_SLASH,
        ),
      },
    } as unknown as SubOrderInitialValues;
  }
};

export const getPromocodeAppliedMessage = (
  promocodeStatus?: PromocodeStatus,
) => {
  if (promocodeStatus === PromocodeStatus.ERROR)
    return 'The promo code was not applied!';

  if (promocodeStatus === PromocodeStatus.IN_PROGRESS)
    return 'Promocode is being applied. This may take a while. When promocode is applied, a corresponding line will appear in order breakdown.';
};
