import { useEffect, useMemo, useState } from 'react';
import { AutocompleteAddress } from 'shared';

const normalizeAutocompleteData = ({
  currentData,
}: {
  currentData?: AutocompleteAddress | null;
}) => {
  if (!currentData) return;

  const {
    postal_code,
    postal_code_suffix,
    administrative_area_level_1,
    country,
    locality,
    street_number,
    route,
  } = currentData;

  const zipCode = postal_code_suffix
    ? `${postal_code?.short}-${postal_code_suffix?.short}`
    : postal_code?.long;

  return {
    country: country?.long,
    city: locality?.long,
    state: administrative_area_level_1?.long,
    zip_code: postal_code ? zipCode : '',
    street_address_one: `${street_number?.short ?? ''} ${route?.short ?? ''}`,
    address: `${street_number?.long ?? ''} ${route?.short ?? ''}`,
  };
};

export const useAutocompleteAddress = <T>({
  setValues,
}: {
  setValues?: (data: T) => void;
}) => {
  const [currentData, setCurrentData] = useState<AutocompleteAddress | null>(
    null,
  );

  const fillData = useMemo(
    () =>
      normalizeAutocompleteData({
        currentData,
      }) as T,
    [currentData],
  );

  useEffect(() => {
    if (currentData && setValues) {
      setValues(fillData as T);
    }
  }, [currentData, fillData, setValues]);

  return { setCurrentData, fillData };
};
