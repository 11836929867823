import { useNavigate } from 'react-router-dom';
import { IconTrash } from '@tabler/icons-react';
import { ActionIcon, Box, Grid, Text } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import { openConfirmModal } from '@mantine/modals';

import { HintSection, SearchInput, SearchInputSingleLayout } from 'components';
import {
  CANCEL_BTN,
  DELETE_BTN,
  getComplianceStatus,
  getSubOrderPath,
  orderHintOptions,
  PROPS_DATA_TABLE,
} from 'shared';
import { subOrderColumns } from '../../constants';
import { useCorporateSubOrders } from '../../hooks';

type Props = {
  rootPath: string;
  disabled: boolean;
  parentOrderId?: string;
  handleDeleteSubOrder: ({
    id,
    parentOrderId,
  }: {
    id: string;
    parentOrderId?: string;
  }) => void;
};

export const InventoryDetailsSubOrders = ({
  rootPath,
  disabled,
  parentOrderId,
  handleDeleteSubOrder,
}: Props) => {
  const navigate = useNavigate();
  const {
    output,
    page,
    isLoading,
    totalRecords,
    search_string,
    updateFilters,
    handleSetSearchParams,
  } = useCorporateSubOrders({
    id: parentOrderId,
  });

  const openDeleteSubOrderModal = ({
    id,
    number,
    parentOrderId,
  }: {
    id: string;
    number: number;
    parentOrderId?: string;
  }) =>
    openConfirmModal({
      size: 'lg',
      title: (
        <Text size="sm" weight={600}>
          Delete Sub-Order?
        </Text>
      ),
      children: (
        <Text size="sm">
          Are you sure you want to delete sub-order #{number}?
        </Text>
      ),
      labels: {
        confirm: <Text data-testid={DELETE_BTN}>Delete</Text>,
        cancel: <Text data-testid={CANCEL_BTN}>Back</Text>,
      },
      onConfirm: () => handleDeleteSubOrder({ id, parentOrderId }),
    });

  return (
    <Grid mb="xl" gutter="xl">
      <Grid.Col mb="xs">
        <Text size="lg" weight={600}>
          Inventory Details
        </Text>
      </Grid.Col>
      <Grid.Col mb="xs">
        <SearchInputSingleLayout>
          <SearchInput
            label="Search"
            value={search_string}
            handleSubmit={(value: string) =>
              updateFilters({ search_string: value })
            }
          />
        </SearchInputSingleLayout>
        <Grid.Col>
          <DataTable
            {...PROPS_DATA_TABLE}
            page={page}
            records={output}
            totalRecords={totalRecords}
            fetching={isLoading}
            onPageChange={(p) => handleSetSearchParams({ page: p.toString() })}
            onRowClick={({ id: subOrderId }) =>
              navigate(
                getSubOrderPath({
                  rootPath,
                  subOrderId,
                  id: parentOrderId ?? '',
                }),
              )
            }
            columns={[
              {
                accessor: 'compliance_status',
                title: '',
                render: ({ compliance_status }) => {
                  return (
                    <Box
                      sx={{
                        width: '2px',
                        height: '40px',
                        margin: '-8px -8px',
                        backgroundColor: getComplianceStatus(compliance_status),
                      }}
                    />
                  );
                },
                width: '3px',
              },
              ...subOrderColumns,
              {
                accessor: 'id',
                title: '',
                render: ({ id, number }) => (
                  <ActionIcon
                    size="md"
                    disabled={disabled}
                    data-testid={DELETE_BTN}
                    onClick={(e) => {
                      e.stopPropagation();
                      openDeleteSubOrderModal({ id, number, parentOrderId });
                    }}
                    color="dark.9"
                  >
                    <IconTrash stroke={1} />
                  </ActionIcon>
                ),
              },
            ]}
          />
        </Grid.Col>
        <Grid.Col>
          <HintSection options={orderHintOptions.slice(0, 2)} />
        </Grid.Col>
      </Grid.Col>
    </Grid>
  );
};
