import { useNavigate } from 'react-router-dom';
import { useForm } from '@mantine/form';
import {
  Button,
  Container,
  Flex,
  Grid,
  Group,
  Select,
  Text,
} from '@mantine/core';

import { ConfirmModal, GeneralInfo, SummaryOrder } from 'components';
import {
  ClubMemberShip,
  CorporateOrderPayload,
  getSubOrderPath,
  NestedRoutes,
  OrderPaymentStatus,
  OrderStatus,
  PAYMENT_METHOD_DROPDOWN,
  PaymentByCheck,
  PaymentMethod,
  SelectOption,
} from 'shared';
import { InventoryDetailsSubOrders } from '../inventoryDetailsSubOrders';
import { PaymentReceived } from './paymentReceived';
import { InvoiceButtons } from './InvoiceButtons';

type Props = {
  initialValues?: CorporateOrderPayload;
  rootPath: string;
  urlInvoice?: string;
  userCards?: SelectOption[];
  paymentByCheck?: PaymentByCheck;
  threeDSecureUrl?: string;
  orderTotalPrice?: string;
  summary?: [string, string][];
  isProcessingApplied: boolean;
  isPaying: boolean;
  isDeleting: boolean;
  isRefunding: boolean;
  defaultClubMemberShip: ClubMemberShip | null;
  promocode: JSX.Element;
  handleConfirmAndPay: () => void;
  handleRefund: () => void;
  handleDelete: () => void;
  handleUpdateOrder: (data: CorporateOrderPayload) => void;
  handleDeleteSubOrder: ({
    id,
    parentOrderId,
  }: {
    id: string;
    parentOrderId?: string;
  }) => void;
  setPaymentByCheckData: React.Dispatch<
    React.SetStateAction<PaymentByCheck | null>
  >;
};

export const EditForm = ({
  rootPath,
  initialValues,
  paymentByCheck,
  userCards,
  urlInvoice,
  threeDSecureUrl,
  summary,
  orderTotalPrice,
  defaultClubMemberShip,
  promocode,
  isPaying,
  isDeleting,
  isRefunding,
  isProcessingApplied,
  handleRefund,
  handleDelete,
  handleUpdateOrder,
  handleConfirmAndPay,
  handleDeleteSubOrder,
  setPaymentByCheckData,
}: Props) => {
  const navigate = useNavigate();
  const form = useForm({
    initialValues,
  });

  const { values, isDirty, onSubmit } = form;

  const { id, status, number, payment_status } = initialValues ?? {};

  const isPaid = payment_status === OrderPaymentStatus.PAID;

  const isCheck = initialValues?.payment_method === PaymentMethod.check;

  const completePayment =
    !!payment_status &&
    [
      OrderPaymentStatus.PAID,
      OrderPaymentStatus.REFUND,
      OrderPaymentStatus.PROCESSING_PAYMENT,
    ].includes(payment_status);

  const isRecalculated =
    !!status &&
    [
      OrderStatus.RECALCULATION_REQUIRED,
      OrderStatus.RECALCULATION_IS_SCHEDULED,
      OrderStatus.RECALCULATION_IN_PROGRESS,
      OrderStatus.RECALCULATION_ERROR,
    ].includes(status);

  const disabled =
    (!!status && status === OrderStatus.CLOSED) ||
    isRecalculated ||
    isProcessingApplied ||
    completePayment;

  const defaultCard = userCards?.find(
    ({ value }) => values.customer_card_id === value,
  )?.label;

  const confirmAndPayDescription = `You are about to pay for the order #${number} using ${defaultCard}`;
  const confirmAndPayTitle =
    'Are you sure you want to pay this Corporate Order?';

  const confirmClearDescription = `Are you sure you want to clear order  #${number}? This action will delete all created sub-orders. This action can not be undone.\n\n Do you want to proceed?`;
  const confirmClearTitle = 'Clear Order?';

  const cancelLabel = 'Back';

  return (
    <form
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault();
        }
      }}
      onSubmit={onSubmit(handleUpdateOrder)}
    >
      <GeneralInfo<CorporateOrderPayload>
        form={form}
        clubMemberShip={defaultClubMemberShip}
      />
      <InventoryDetailsSubOrders
        disabled={disabled}
        parentOrderId={id}
        rootPath={rootPath}
        handleDeleteSubOrder={handleDeleteSubOrder}
      />
      {summary?.map((i) => <SummaryOrder key={i[0]} item={i} />)}

      <Button
        variant="white"
        onClick={() =>
          navigate(
            getSubOrderPath({
              rootPath,
              subOrderId: NestedRoutes.Create,
              id: id ?? '',
            }),
          )
        }
      >
        + Add Recipient
      </Button>

      {promocode}
      <Grid>
        <Grid.Col>
          <Text weight={600} size="lg" mb="sm">
            Payment Information
          </Text>
        </Grid.Col>
        <Grid.Col span={4}>
          <Select
            required
            label="Payment Method"
            data-testid={PAYMENT_METHOD_DROPDOWN}
            data={userCards}
            {...form.getInputProps('customer_card_id')}
            onChange={(value) => {
              if (value === PaymentMethod.check) {
                form.setFieldValue('payment_method', PaymentMethod.check);
              } else {
                form.setFieldValue('payment_method', PaymentMethod.card);
              }
              form.setFieldValue('customer_card_id', value);
            }}
          />
        </Grid.Col>
        <Grid.Col span={4} mt={28}>
          {orderTotalPrice}
        </Grid.Col>
        {isCheck && (
          <InvoiceButtons
            urlInvoice={urlInvoice}
            setInvoice={form.setFieldValue}
          />
        )}
        {isPaid && !isCheck && (
          <Grid.Col span={4} mt={28}>
            <Flex justify="flex-end">
              <Button onClick={handleRefund} loading={isRefunding}>
                Refund
              </Button>
            </Flex>
          </Grid.Col>
        )}
        {threeDSecureUrl && !isPaid && (
          <Grid.Col span={4} mt={28}>
            <Flex justify="flex-end">
              <Button href={threeDSecureUrl} component="a">
                Confirm Three D Secure
              </Button>
            </Flex>
          </Grid.Col>
        )}
      </Grid>

      <PaymentReceived
        isCheck={isCheck}
        paymentByCheck={paymentByCheck}
        setPaymentByCheckData={setPaymentByCheckData}
      />

      <Container size="xs" px={0} mt={50}>
        <Group>
          <Button fullWidth type="submit" disabled={!isDirty() || disabled}>
            Save
          </Button>

          <ConfirmModal
            fullWidth
            type="button"
            variant="outline"
            modalSize="lg"
            label="Confirm and Pay"
            confirmLabel="Confirm Payment"
            loading={isPaying}
            disabled={disabled}
            cancelLabel={cancelLabel}
            title={confirmAndPayTitle}
            onConfirm={handleConfirmAndPay}
          >
            <Text>{confirmAndPayDescription}</Text>
            <Text size="lg" weight={600} mt={10}>
              {orderTotalPrice}
            </Text>
          </ConfirmModal>

          <ConfirmModal
            fullWidth
            type="button"
            variant="white"
            modalSize="lg"
            label="Clear Order"
            confirmLabel="Clear Order"
            loading={isDeleting}
            disabled={disabled}
            cancelLabel={cancelLabel}
            title={confirmClearTitle}
            onConfirm={handleDelete}
          >
            <Text style={{ whiteSpace: 'pre-line' }}>
              {confirmClearDescription}
            </Text>
          </ConfirmModal>
        </Group>
      </Container>
    </form>
  );
};
