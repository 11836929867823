import { useCallback, useEffect, useState } from 'react';
import { SetFieldValue } from '@mantine/form/lib/types';

import { useApiMutation, useUploadFileByPreSignedUrl } from 'hooks';
import { CorporateOrderPayload } from 'shared';
import { GeneralService } from 'App/api';

export const useUploadInvoice = ({
  setInvoice,
}: {
  setInvoice: SetFieldValue<CorporateOrderPayload>;
}) => {
  const [file, setFile] = useState<File | null>(null);

  const { uploadFileToS3, isLoading: isUploading } =
    useUploadFileByPreSignedUrl();

  const { isLoading: isLoadingPreSignedUrl, mutateAsync: getPreSignedUrl } =
    useApiMutation(['getPreSignedUrl'], GeneralService.privateUploadUrl);

  const uploadFile = useCallback(
    async (file: File) => {
      const { url, fields } = await getPreSignedUrl({
        document_name: file.name,
      });

      await uploadFileToS3({ file, preSignedUrl: url, fields });

      setInvoice('invoice_file', fields.key);
    },
    [getPreSignedUrl, setInvoice, uploadFileToS3],
  );

  useEffect(() => {
    if (file) {
      uploadFile(file);
    }
  }, [file, uploadFile]);

  return { setFile, isLoading: isUploading || isLoadingPreSignedUrl };
};
