import {
  InventoryDetail,
  OrderDto,
  UpcomingShipmentList,
  capitalizeText,
  getCheckoutSummary,
  getCurrencyString,
  getDateSOutput,
  orderPaymentStatusLabels,
  salesOrderStatusLabels,
} from 'shared';

const normalizeOrderItem = (item: InventoryDetail): UpcomingShipmentList => {
  const { sku_name, quantity, price, discount, total, id = '' } = item;

  return {
    id,
    quantity,
    product_name: sku_name,
    retail_price: getCurrencyString(price),
    discounted_price: getCurrencyString(discount),
    total_price: getCurrencyString(total),
  };
};

export const getOrderInfo = (data: OrderDto) => {
  const {
    status,
    payment_status,
    billing_datetime,
    delivery_method,
    shipping_date,
    freight_company,
    freight_method,
  } = data;

  return [
    ['Order Status', salesOrderStatusLabels[status]],
    ['Payment Status', orderPaymentStatusLabels[payment_status]],
    ['Billing Date', getDateSOutput(billing_datetime)],
    ['Billing Time', getDateSOutput(billing_datetime, 'HH:mm A')],
    ['Delivery Method', delivery_method],
    ['Shipment Date', shipping_date?.replaceAll('-', '/')],
    ['Freight Company', capitalizeText(freight_company as string)],
    ['Freight Method', capitalizeText(freight_method as string)],
  ] as [string, string][];
};

export const normalizeOrderList = (orders?: OrderDto[]) => {
  if (!orders) return [];

  return orders.map((i) => ({
    ...i,
    summary:
      i.checkout &&
      getCheckoutSummary(i.checkout).filter(
        (i) => i[0] !== 'Fee' && i[0] !== 'Gratuity',
      ),
    items: i.items?.map(normalizeOrderItem),
    orderInfo: getOrderInfo(i),
  }));
};
