import {
  CreateDiscount,
  DiscountDto,
  PaginatedRequest,
  PaginatedResponse,
} from 'shared';
import { DISCOUNTS_PATH } from '../../endpoints';
import request from '../../request';

export default class DiscountService {
  static readonly create = (data: CreateDiscount) => {
    return request<{ id: string }>({
      url: DISCOUNTS_PATH,
      method: 'post',
      data,
    });
  };

  static readonly getOne = ({ id }: { id?: string }) => {
    return request<DiscountDto>({
      url: `${DISCOUNTS_PATH}/${id}`,
      method: 'get',
    });
  };

  static readonly getCollection = (params: PaginatedRequest) => {
    return request<PaginatedResponse<DiscountDto>>({
      url: DISCOUNTS_PATH,
      params,
      method: 'get',
    });
  };

  static readonly update = ({
    id,
    data,
  }: {
    id?: string;
    data: CreateDiscount;
  }) => {
    return request<DiscountDto>({
      url: `${DISCOUNTS_PATH}/${id}`,
      method: 'put',
      data,
    });
  };

  static readonly delete = ({ id }: { id: string }) => {
    return request({
      url: `${DISCOUNTS_PATH}/${id}`,
      method: 'delete',
    });
  };
}
