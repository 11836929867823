import { generatePath } from 'react-router-dom';

export enum NestedRoutes {
  List = 'list',
  Edit = 'edit/:id',
  Create = 'create',
  View = 'view/:id',
  Arrange = 'arrange',
  Receive = 'receive/:id',
  TabEdit = 'edit/:id/:tab',
  SubOrder = 'edit/:id/suborder/:subOrderId',
}

const generateInnerPath = ({
  path,
  id,
  tab,
  subOrderId,
}: {
  path: string;
  id: string;
  tab?: string;
  subOrderId?: string;
}) => generatePath(path, { id, tab, subOrderId });

export const getEditPath = (rootPath: string, id: string): string =>
  generateInnerPath({ path: `${rootPath}/${NestedRoutes.Edit}`, id });

export const getViewPath = (rootPath: string, id: string): string =>
  generateInnerPath({ path: `${rootPath}/${NestedRoutes.View}`, id });

export const getCreatePath = (rootPath: string): string =>
  `${rootPath}/${NestedRoutes.Create}`;

export const getReceivePath = (rootPath: string, id: string): string =>
  generateInnerPath({ path: `${rootPath}/${NestedRoutes.Receive}`, id });

export const getTabEditPath = (
  rootPath: string,
  id: string,
  tab?: string,
): string =>
  generateInnerPath({ path: `${rootPath}/${NestedRoutes.TabEdit}`, id, tab });

export const getSubOrderPath = ({
  rootPath,
  id,
  subOrderId,
}: {
  id: string;
  rootPath: string;
  subOrderId: string;
}): string =>
  generateInnerPath({
    path: `${rootPath}/${NestedRoutes.SubOrder}`,
    id,
    subOrderId,
  });
