import { useParams } from 'react-router-dom';
import { EditSuborder } from './EditSuborder';
import { NestedRoutes } from 'shared';
import { CreateSuborder } from './CreateSuborder';

export const Suborder = () => {
  const { subOrderId } = useParams();

  const isCreatePath = subOrderId === NestedRoutes.Create;

  if (isCreatePath) return <CreateSuborder />;

  return <EditSuborder />;
};
