import { ReactElement } from 'react';
import { Alert, Flex, Text } from '@mantine/core';
import { SalesOrderComplianceStatus } from 'shared';
import { status } from './constants';

type Props = {
  complianceStatus: SalesOrderComplianceStatus | null;
  action?: ReactElement | null;
};

export const ShipcompliantStatus = ({ complianceStatus, action }: Props) => {
  const color = status[complianceStatus ?? 'default'].color;
  const text = status[complianceStatus ?? 'default'].text;

  return (
    <Alert color={color} mb="lg">
      <Flex justify="space-between" align="center">
        <Text>{text}</Text>
        {action}
      </Flex>
    </Alert>
  );
};
