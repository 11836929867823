import { ReactNode } from 'react';
import { Stepper, Container } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useCreateCorporateOrder, useUploadRecipientFile } from '../../hooks';
import { GeneralInfoForm } from './generalInfo';
import { corporateOrderSchema } from './schema';
import { CorporateOrderButtons } from './CorporateOrderButtons';
import { mockInitialValues } from '../../constants';
import {
  ProgressBar,
  RecipientUpload,
  UploadErrorModal,
} from './recipientUpload';
import { CorporateOrderDto, CorporateOrderPayload, SelectOption } from 'shared';

export enum LabelSteps {
  GeneralInfo = 'General Info',
  RecipientUpload = 'Recipient Upload',
}

export interface IStepper {
  label: LabelSteps;
  component: ReactNode;
}

type Props = {
  initialValues?: CorporateOrderPayload;
  initialStep?: number;
  errors?: CorporateOrderDto['errors'];
  isEdit?: boolean;
  rootPath?: string;
  defaultCustomer?: SelectOption;
  isDeleting?: boolean;
  handleDelete?: () => void;
  handleRefetchOrder?: () => void;
};

export const CreateForm = ({
  errors,
  initialValues,
  defaultCustomer,
  rootPath,
  isEdit = false,
  isDeleting,
  initialStep = 0,
  handleDelete = () => null,
  handleRefetchOrder = () => null,
}: Props) => {
  const { customer_id, recipients_file_url, status } = initialValues ?? {};
  const {
    activeStep,
    userCards,
    initialCustomer,
    asyncSelectUsers,
    isProcessing,
    isLoadingUsers,
    handleSubmit,
    setActiveStep,
    getCustomerDataByCustomerId,
  } = useCreateCorporateOrder({
    rootPath,
    initialStep,
    handleRefetchOrder,
    initialCustomerId: customer_id,
  });
  const {
    file,
    errors: uploadErrors,
    setFile,
    setErrors,
  } = useUploadRecipientFile();

  const hasFile = !!file;
  const hasDownloadFile = !!recipients_file_url;

  const form = useForm({
    initialValues: initialValues ?? mockInitialValues,
    validate: yupResolver(corporateOrderSchema),
    validateInputOnBlur: true,
  });

  const steps: IStepper[] = [
    {
      label: LabelSteps.GeneralInfo,
      component: (
        <GeneralInfoForm
          form={form}
          userCards={userCards}
          defaultCustomer={defaultCustomer ?? initialCustomer}
          isLoadingUsers={isLoadingUsers}
          asyncSelectUsers={asyncSelectUsers}
          getCustomerDataByCustomerId={getCustomerDataByCustomerId}
        />
      ),
    },
    {
      label: LabelSteps.RecipientUpload,
      component: hasDownloadFile ? (
        <ProgressBar
          status={status}
          errors={errors}
          handleRefetchOrder={() => {
            handleRefetchOrder();
            setFile(null);
          }}
        />
      ) : (
        <RecipientUpload file={file} setFile={setFile} />
      ),
    },
  ];

  const isInvalid = !form.isValid();
  const hasShipmentDate = !!form.values.shipping_date;
  const disabledSteps = isInvalid || hasDownloadFile || !hasShipmentDate;

  return (
    <form
      onSubmit={form.onSubmit((data) => handleSubmit({ data, isEdit, file }))}
    >
      <Stepper active={activeStep} onStepClick={setActiveStep}>
        {steps.map(({ label, component }) => (
          <Stepper.Step key={label} label={label} disabled={disabledSteps}>
            {component}
          </Stepper.Step>
        ))}
      </Stepper>

      <Container size="xs" px={0}>
        <CorporateOrderButtons
          hasFile={hasFile}
          hasDownloadFile={hasDownloadFile}
          activeStep={activeStep}
          isProcessing={isProcessing}
          isDeleting={isDeleting}
          isInvalidGeneral={isInvalid}
          hasShipmentDate={hasShipmentDate}
          setActiveStep={setActiveStep}
          handleDelete={handleDelete}
        />
      </Container>

      <UploadErrorModal
        errors={uploadErrors}
        setErrors={setErrors}
        setFile={setFile}
      />
    </form>
  );
};
