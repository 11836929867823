import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { indexBy } from 'rambda';
import { useQueryClient } from '@tanstack/react-query';

import { CorporateOrder, ShipmentSalesOrdersService } from 'App/api';
import { useApiMutation, useApiQuery, useCustomers, useToast } from 'hooks';
import {
  ClubMemberShip,
  formatDate,
  getCheckoutSummary,
  getClubMembership,
  getCurrencyString,
  getSelectOptions,
  NestedRoutes,
  SelectOption,
  SubOrderDto,
  SubOrderInitialValues,
} from 'shared';
import { getSubOrderInitialValues } from '../helper';

const key = 'suborder';

export const useSuborder = ({ parentOrderId }: { parentOrderId: string }) => {
  const { subOrderId = '' } = useParams();
  const navigate = useNavigate();
  const [customerClubMembership, setCustomerClubMembership] =
    useState<ClubMemberShip | null>(null);

  const addToast = useToast();
  const client = useQueryClient();

  const goBack = () => navigate(-1);

  const {
    userOptions,
    output: users,
    hasMore: hasMoreUsers,
    isLoading: isLoadingUsers,
    setPageSelectAsync: setPageSelectUsers,
    handleSetSearchString: handleSetSearchStringUsers,
  } = useCustomers({
    isSelectAsync: true,
  });

  const { data: subOrder, isLoading } = useApiQuery(
    [key, subOrderId],
    () => CorporateOrder.getOneSubOrder({ parentOrderId, subOrderId }),
    { enabled: subOrderId !== NestedRoutes.Create },
  );

  const { data: parentOrder, isLoading: isLoadingParentOrder } = useApiQuery(
    ['corporateOrderGetOne', parentOrderId],
    () => CorporateOrder.getOne(parentOrderId),
  );

  const { mutate: edit, isLoading: isEditing } = useApiMutation(
    ['editSuborder', subOrderId],
    CorporateOrder.editSubOrder,
    {
      onSuccess: (data) => {
        addToast.success('Order has been updated!');
        client.setQueriesData([key, subOrderId], data);
      },
    },
  );

  const { mutate: create, isLoading: isCreating } = useApiMutation(
    ['editSuborder', subOrderId],
    CorporateOrder.createSuborder,
    {
      onSuccess: () => {
        addToast.success('Suborder has been created!');
        goBack();
      },
    },
  );

  const { mutate: recheckCompliance, isLoading: isComplianceCheckProcessing } =
    useApiMutation(
      ['recheckCompliance', subOrderId],
      ShipmentSalesOrdersService.recheckCompliance,
      {
        onSuccess: () =>
          addToast.success('Successfully submitted for compliance check!'),
      },
    );

  const {
    customer,
    checkout,
    number,
    gift_package_id,
    sku_discounted_prices_sum,
    sku_prices_sum,
    sku_prices_discounts_sum,
  } = subOrder ?? {};

  const { gift_packages } = parentOrder ?? {};

  const initialValues = useMemo(
    () => getSubOrderInitialValues(subOrder as unknown as SubOrderDto),
    [subOrder],
  );

  const defaultClubMembership = useMemo(
    () =>
      getClubMembership({
        mainTier: customer?.main_tier,
        addOnTier: customer?.add_on_tier,
      }),
    [customer],
  );

  const getClubMembershipByCustomer = (id: string) => {
    const user = users.find((i) => i.id === id);

    setCustomerClubMembership(
      getClubMembership({
        mainTier: user?.wine_club_tier,
        addOnTier: user?.wine_club_add_on,
      }),
    );
  };

  const summary = useMemo(
    () => checkout && getCheckoutSummary(checkout),
    [checkout],
  )?.filter((i) => i[0] !== 'Gratuity' && i[0] !== 'Member’s Discount');

  const giftPackageOptions = useMemo(
    () =>
      gift_packages &&
      getSelectOptions(
        gift_packages?.map(({ id, name }) => ({ id: id ?? '', name })),
      ),
    [gift_packages],
  ) as SelectOption[];

  const subTotal =
    (checkout?.items_price ?? 0) -
    (checkout?.discounts ?? 0) -
    (checkout?.promocode_discounts ?? 0);

  const defaultGiftPackage = {
    id: gift_package_id,
    discountedPrice: getCurrencyString(sku_discounted_prices_sum ?? 0),
    retailPrice: getCurrencyString(sku_prices_sum ?? 0),
    discountPrice: getCurrencyString(sku_prices_discounts_sum ?? 0),
    total: getCurrencyString(subTotal),
  };

  const giftPackage = indexBy('id', gift_packages ?? []);

  const getSuborderPayload = (data: SubOrderInitialValues) => {
    const { shipping_date, shipping_address, items, customer_id } = data;

    const payload = {
      ...data,
      items: items.map(({ sku, sku_id, quantity }) => ({
        quantity,
        sku_id: sku?.id ?? sku_id,
      })),
      customer_id: customer_id === 'null' ? null : customer_id,
      shipping_date: formatDate(shipping_date),
      shipping_address: {
        ...shipping_address,
        street_address_one: shipping_address.address_line_1,
        street_address_two: shipping_address.address_line_2,
        date_of_birth: formatDate(shipping_address.date_of_birth) ?? '',
      },
    };
    return payload;
  };

  const handleEditSuborder = (data: SubOrderInitialValues) => {
    const payload = getSuborderPayload(data);

    edit({ parentOrderId, subOrderId, data: payload });
  };

  const handleCreateSuborder = (data: SubOrderInitialValues) => {
    const payload = getSuborderPayload(data);

    create({
      parentOrderId,
      data: payload,
    });
  };

  const handleRecheckCompliance = () => {
    recheckCompliance({ id: subOrderId });
  };

  return {
    number,
    summary,
    giftPackage,
    initialValues,
    giftPackageOptions,
    defaultGiftPackage,
    defaultClubMembership: customerClubMembership ?? defaultClubMembership,
    isEditing,
    isCreating,
    isLoadingUsers,
    isComplianceCheckProcessing,
    isLoading: isLoadingParentOrder || isLoading,
    users,
    asyncSelectUsers: {
      hasMore: hasMoreUsers,
      options: userOptions,
      setPageSelectAsync: setPageSelectUsers,
      setSearchStringSelectAsync: handleSetSearchStringUsers,
    },
    getClubMembershipByCustomer,
    handleEditSuborder,
    handleCreateSuborder,
    handleRecheckCompliance,
  };
};
